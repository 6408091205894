import { type ReactNode, useRef } from "react";

type HiddenFileInputProps = {
  onChange?: (imageUri?: string) => void;
  renderControl: ({ onSelect }: { onSelect: () => void }) => ReactNode;
};

export function HiddenFileInput(props: HiddenFileInputProps) {
  const { renderControl, onChange } = props;
  const inputReference = useRef<HTMLInputElement>(null);
  return (
    <>
      {renderControl({ onSelect: () => inputReference.current?.click() })}
      <input
        ref={inputReference}
        type="file"
        style={{ display: "none" }}
        accept="image/x-png,image/gif,image/jpeg"
        onChange={(event) => {
          const image = event?.target?.files?.[0];
          if (image) {
            const imageURl = URL.createObjectURL(image);
            onChange?.(imageURl);
            event.target.value = "";
          } else {
            onChange?.(undefined);
            event.target.value = "";
          }
        }}
      />
    </>
  );
}
