import { ApplicationError } from "~/modules/error";

export async function checkError(response: Response) {
  if (response.status >= 400 && response.status < 500) {
    let json;
    const message = await response.text();
    try {
      json = JSON.parse(message);
    } catch {
      throw new ApplicationError(message || response.statusText, { status: response.status });
    }
    const errorMessage = json.errors
      ? json.errors.map((error: any) => error.msg).join(" ")
      : json.message;
    throw new ApplicationError(errorMessage, { status: response.status });
  }

  if (response.status >= 500) {
    const text = await response.text();
    throw new ApplicationError(text, { status: 500 });
  }
}
