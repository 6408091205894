import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import { type Control, Controller, useController, useWatch } from "react-hook-form";

import type { WishlistItemType } from "~/types/wishlist";

const MS_IN_DAY = 1000 * 60 * 60 * 24;

const INTERVALS = {
  daily: MS_IN_DAY,
  weekly: MS_IN_DAY * 7,
  biweekly: MS_IN_DAY * 7 * 2,
  monthly: MS_IN_DAY * 30,
};

type ItemTypeInputProps = {
  // TODO: Handle control type
  control: Control<any>;
  disabled?: boolean;
};

export function ItemTypeInput({ control, disabled }: ItemTypeInputProps) {
  const selectedType: WishlistItemType = useWatch({ control, name: "type" });

  const { field: subscriptionIntervalsField } = useController({
    name: "subscriptionIntervals",
    control,
    defaultValue: [],
  });

  const handleChangeSubscriptionIntervals = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, checked } = event.target;
      const { value: values, onChange } = subscriptionIntervalsField;
      const newValues = checked ? [...values, +value] : values.filter((_: number) => _ !== +value);
      onChange(newValues);
    },
    [subscriptionIntervalsField],
  );

  return (
    <Box>
      <Selection>
        <RadioController control={control} value="item" label="Single Wish" disabled={disabled} />
        <Collapse in={selectedType === "item"}>
          <Box boxSizing="border-box" px={2} py={1} sx={{ borderTop: 1, borderColor: "grey.100" }}>
            <FormControlLabel
              control={
                <Controller
                  name="repeatPurchases"
                  control={control}
                  defaultValue={false}
                  render={(props) => (
                    <Checkbox
                      {...props.field}
                      checked={props.field.value}
                      onChange={(event) => {
                        props.field.onChange(event.target.checked);
                      }}
                    />
                  )}
                />
              }
              label="Allow Repeat Purchases"
            />
            <FormHelperText>
              Check if you want repeat purchases of this gift. If unchecked, the item will
              automatically delete from your wishlist after the first purchase.
            </FormHelperText>
          </Box>
        </Collapse>
      </Selection>
      <Selection>
        <RadioController
          control={control}
          value="subscription"
          label="Subscription"
          disabled={disabled}
        />
        <Collapse in={selectedType === "subscription"}>
          <Box boxSizing="border-box" px={2} py={1} sx={{ borderTop: 1, borderColor: "grey.100" }}>
            <Grid container>
              <Grid item xs={7}>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...subscriptionIntervalsField}
                      value={0}
                      checked={subscriptionIntervalsField.value.includes(0)}
                      onChange={handleChangeSubscriptionIntervals}
                    />
                  }
                  label="Allow one-time purchases"
                />
              </Grid>
              <Grid item xs={5}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  {Object.entries(INTERVALS).map(([key, value]) => (
                    <FormControlLabel
                      key={key}
                      control={
                        <Checkbox
                          {...subscriptionIntervalsField}
                          value={value}
                          checked={subscriptionIntervalsField.value.includes(value)}
                          onChange={handleChangeSubscriptionIntervals}
                        />
                      }
                      label={getIntervalLabel(value)}
                    />
                  ))}
                </Box>
              </Grid>
            </Grid>
            <FormHelperText>
              Allows gifter to purchase this item on a recurring basis.
            </FormHelperText>
          </Box>
        </Collapse>
      </Selection>
      <Selection>
        <RadioController
          control={control}
          value="crowdfunding"
          label="Crowdfund"
          disabled={disabled}
        />
        <Collapse in={selectedType === "crowdfunding"}>
          <Box boxSizing="border-box" px={2} py={1} sx={{ borderTop: 1, borderColor: "grey.100" }}>
            <FormHelperText>
              Allows multiple gifters to contribute to your wish item.
            </FormHelperText>
          </Box>
        </Collapse>
      </Selection>
    </Box>
  );
}

function RadioController({
  value,
  label,
  control,
  disabled,
}: {
  value: string;
  label: string;
  control: Control<any>;
  disabled?: boolean;
}) {
  return (
    <Controller
      name="type"
      control={control}
      render={(props) => (
        <Box
          component="label"
          display="flex"
          alignItems="center"
          sx={{ cursor: "pointer", "&:has(:checked)": { backgroundColor: "#56BBEC10" } }}
          p={0.5}
        >
          <Radio
            {...props.field}
            value={value}
            checked={props.field.value === value}
            onChange={(event) => {
              props.field.onChange(event.target.value);
            }}
            disabled={disabled}
          />
          <Typography>{label}</Typography>
        </Box>
      )}
    />
  );
}

function Selection({ children }: { children: React.ReactNode }) {
  return (
    <Box
      sx={{
        overflow: "hidden",
        cursor: "pointer",
        border: 1,
        borderColor: "grey.300",
        "&:first-of-type": {
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
        },
        "&:last-of-type": {
          borderBottomLeftRadius: "16px",
          borderBottomRightRadius: "16px",
        },
        "&:not(:last-of-type)": {
          borderBottom: "none",
        },
        "& *::checked": {
          backgroundColor: "red",
        },
      }}
    >
      {children}
    </Box>
  );
}

function getIntervalLabel(interval: number) {
  switch (interval) {
    case INTERVALS.daily: {
      return "Daily";
    }
    case INTERVALS.weekly: {
      return "Weekly";
    }
    case INTERVALS.biweekly: {
      return "Bi Weekly";
    }
    case INTERVALS.monthly: {
      return "Monthly";
    }
    default: {
      throw new Error(`"${interval}" is not a valid interval.`);
    }
  }
}
