import { Card, CardContent, Typography } from "@mui/material";

import type { Wisher } from "~/types";

import { SocialLinksSection } from "./social-links-section";

export function AboutCard({ wisher }: { wisher: Wisher }) {
  return (
    <Card>
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: 4, p: 3 }}>
        <Typography variant="b5" component="p" color="neutral.7" align="center">
          {wisher.bio}
        </Typography>
        <SocialLinksSection handle={wisher.handle} socialLinks={wisher.socialLinks} />
      </CardContent>
    </Card>
  );
}
