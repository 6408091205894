import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { type ReactNode, useState } from "react";

import { CropImage } from "~/components/crop-image";
import { Dialog } from "~/components/dialog";
import { HiddenFileInput } from "~/components/form/hidden-file-input";

type CropImageDialogProps = {
  dimensions: { width: number; height: number };
  renderControl: (props: { onSelect: () => void }) => ReactNode;
  onCropped?: (image: Blob) => void;
  title?: string;
};

export function CropImageDialog({
  title,
  dimensions,
  renderControl,
  onCropped,
}: CropImageDialogProps) {
  const [image, setImage] = useState<string>();
  const [croppedImage, setCroppedImage] = useState<Blob>();

  function handleClose() {
    setImage(undefined);
    setCroppedImage(undefined);
  }

  function handleSelect() {
    onCropped?.(croppedImage!);
    handleClose();
  }

  return (
    <>
      <HiddenFileInput
        onChange={(imageUri) => setImage(imageUri)}
        renderControl={({ onSelect }) => renderControl({ onSelect })}
      />
      <Dialog.Root maxWidth="xs" variant="normal" open={!!image} fullWidth onClose={handleClose}>
        <Dialog.Title>{title || "Crop image"}</Dialog.Title>
        <Box>
          <Dialog.Content>
            <CropImage
              cropShape="rect"
              image={image}
              onCrop={setCroppedImage}
              aspectRatio={dimensions.width / dimensions.height}
              dimensions={dimensions}
            />
            <Box mt={4} />
            <LoadingButton fullWidth variant="contained" onClick={handleSelect}>
              <span>Crop</span>
            </LoadingButton>
          </Dialog.Content>
        </Box>
      </Dialog.Root>
    </>
  );
}
