import {
  Avatar,
  Box,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Link,
  Typography,
} from "@mui/material";
import { Link as RemixLink } from "@remix-run/react";
import { type ReactNode, useState } from "react";
import { useToggle } from "usehooks-ts";

import { Button } from "~/components/button";
import { Dialog } from "~/components/dialog";
import {
  BankTwoTonesIcon,
  FileListTwoTonesIcon,
  FileViewTwoTonesIcon,
  GiftTwoTonesIcon,
  UserTwoTonesIcon,
} from "~/components/icons";
import { PAYOUT_FEES_DETAIL_URL, PLATFORM_TERMS_URL } from "~/constants/platform";
import { Routes } from "~/constants/routes";
import { useAuth } from "~/hooks/use-auth";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";

type WishlistActivationDialogProps = {
  wisherId: string;
  isNewWisher?: boolean;
  renderControl: (props: { toggle: () => void; open: boolean }) => ReactNode;
};
export function WishlistActivationDialog(props: WishlistActivationDialogProps) {
  const [open, toggle] = useToggle(false);
  return (
    <>
      {props.renderControl({ open, toggle })}
      <Dialog.Root maxWidth="sm" variant="normal" open={open} onClose={toggle}>
        <DialogContent {...props} />
      </Dialog.Root>
    </>
  );
}

function DialogContent(props: Omit<WishlistActivationDialogProps, "renderControl">) {
  const [agree, toggleAgree] = useToggle(false);
  const [activated, setActivated] = useState(false);

  const title = props.isNewWisher ? "Payout Agreement" : "New Payout Agreement";

  const [submit, submitState] = useWtFetcher({
    onSuccess: () => {
      setActivated(true);
    },
    onError: (response) => alert(response.error),
  });

  function handleSubmit() {
    submit.submit(
      {
        wisherId: props.wisherId,
      },
      { method: "POST", action: Routes.actions.wisher.wishlist.activate() },
    );
  }

  if (activated) return <ActivatedContent />;

  return (
    <>
      <Dialog.Content>
        <Dialog.Icon
          sx={{
            width: { xs: 56, sm: 80 },
            height: { xs: 56, sm: 80 },
            bgcolor: "teal.0",
            color: "teal.4",
          }}
        >
          <FileListTwoTonesIcon sx={{ width: { xs: 28, sm: 40 }, height: { xs: 28, sm: 40 } }} />
        </Dialog.Icon>
        <Dialog.Title>{title}</Dialog.Title>
        <Box mt={2} />
        <Typography component="p" variant="b5" textAlign="center" color="neutral.5">
          To activate your wishlist, please agree to the following terms and conditions:
        </Typography>
        <Box mt={3} />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, alignItems: "center" }}>
          <TermCard icon={<UserTwoTonesIcon />} content="I am over 18 and have a valid ID" />
          <TermCard
            icon={<BankTwoTonesIcon />}
            content={
              <>
                <span>I have a bank account in one of </span>
                <Link
                  sx={{ textDecoration: "none" }}
                  href={PAYOUT_FEES_DETAIL_URL}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  the supported countries and understand the payout fees.
                </Link>
              </>
            }
          />
          <TermCard
            icon={<FileViewTwoTonesIcon />}
            content={
              <>
                <span>I am using WishTender within the </span>
                <Link
                  sx={{ textDecoration: "none" }}
                  href={PLATFORM_TERMS_URL}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Terms of Service
                </Link>
                . I will not sell services or goods on my wishlist.
              </>
            }
          />
        </Box>
        <Box mt={3} />
        <FormControl required>
          <FormControlLabel
            control={<Checkbox onChange={() => toggleAgree()} checked={agree} />}
            label="I agree to the terms above. I understand that if this information is not correct, I may not be able to collect my funds."
          />
        </FormControl>
        <Dialog.Actions>
          <Button
            size="lg"
            fullWidth
            onClick={handleSubmit}
            loading={submitState.isFetching}
            disabled={!agree}
          >
            Activate Wishlist
          </Button>
        </Dialog.Actions>
      </Dialog.Content>
    </>
  );
}

function ActivatedContent() {
  const { wisher } = useAuth();

  return (
    <>
      <Dialog.Content>
        <Dialog.Icon
          sx={{
            width: { xs: 56, sm: 80 },
            height: { xs: 56, sm: 80 },
            bgcolor: "green.0",
            color: "green.4",
          }}
        >
          <GiftTwoTonesIcon sx={{ width: { xs: 28, sm: 40 }, height: { xs: 28, sm: 40 } }} />
        </Dialog.Icon>
        <Dialog.Title>Wishlist Activated!</Dialog.Title>
        <Box mt={2} />
        <Typography component="p" variant="b5" textAlign="center" color="neutral.5">
          Your wishlist is activated.
          <br />
          You can now share your wishlist and track your balance.
        </Typography>
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          component={RemixLink}
          to={Routes.wisher.paymentDashboard.main()}
          size="lg"
          fullWidth
          variant="subtle"
        >
          Track my balance
        </Button>
        <Button
          component={RemixLink}
          to={Routes.public.wisher({ username: wisher!.handle })}
          size="lg"
          fullWidth
        >
          Go to Wishlist
        </Button>
      </Dialog.Actions>
    </>
  );
}

function TermCard({ icon, content }: { icon: ReactNode; content: ReactNode }) {
  return (
    <Card
      sx={{
        boxShadow: (theme) => theme.palette.shadows.primary[200],
        maxWidth: 328,
        width: "100%",
      }}
    >
      <Box sx={{ p: 2, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Avatar sx={{ bgcolor: "teal.0", color: "teal.4", flexShrink: 0, mr: 1 }}>{icon}</Avatar>
        <Typography component="div" sx={{ flexGrow: 1 }} variant="sh5" color="neutral.7">
          {content}
        </Typography>
      </Box>
    </Card>
  );
}
