import type { Area } from "react-easy-crop";

async function getImage(source: string): Promise<HTMLImageElement> {
  var image = new Image();
  return new Promise((resolve) => {
    image.addEventListener("load", () => {
      // some odd issue with Safari that requires a setTimeout to ensure image Blob exists
      setTimeout(() => {
        resolve(image);
      }, 100);
    });
    image.src = source;
  });
}

// Extract an Base64 Image's File Extension
export function extractImageFileExtensionFromBase64(base64Data: string): string {
  const regex = /^data:image\/(\w+);base64,/g;
  const result = regex.exec(base64Data);
  return result?.[1] ?? "jpg";
}

export async function getCroppedImage(
  source: string,
  crop: Area,
  dimensions: { width: number; height: number },
): Promise<Blob | undefined> {
  const image = await getImage(source);
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  /* setting canvas width & height allows us to 
    resize from the original image resolution */
  canvas.width = dimensions.width;
  canvas.height = dimensions.height;
  const extension = extractImageFileExtensionFromBase64(source);
  context?.drawImage(
    image,
    crop.x,
    crop.y,
    crop.width,
    crop.height,
    0,
    0,
    canvas.width,
    canvas.height,
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob || undefined);
    }, "image/" + extension);
  });
}
