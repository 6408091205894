import { Avatar, Box, Card, Typography } from "@mui/material";
import { IconCircleChevronRight } from "@tabler/icons-react";

import { Button } from "~/components/button";
import { SparklesFilledIcon } from "~/components/icons";
import { useAuth } from "~/hooks/use-auth";

import { WishlistActivationDialog } from "./activation-dialog";

export function ReactivationCard() {
  const auth = useAuth();

  if (!auth.wisher) return undefined;

  return (
    <Card>
      <Box p={3}>
        <Avatar sx={{ bgcolor: "blue.0", color: "blue.4" }}>
          <SparklesFilledIcon />
        </Avatar>
        <Box mt={2} />
        <Typography component="p" variant="bh7" color="neutral.6">
          Your wishlist is ready to be activated
        </Typography>
        <Box mt={1} />
        <Typography component="p" variant="b5" color="neutral.7">
          Your wishlist is currently deactivated due to payment processor updates.
        </Typography>
        <Box mt={1.5} />
        <Typography component="p" variant="b5" color="neutral.7">
          To re-activate, please check out our policies about your wishlist to work with our new
          payout method.
        </Typography>
        <Box mt={2} />
        <WishlistActivationDialog
          wisherId={auth.wisher.id}
          renderControl={({ toggle }) => (
            <Button
              variant="gradient"
              endIcon={<IconCircleChevronRight />}
              size="lg"
              onClick={toggle}
            >
              Activate Wishlist
            </Button>
          )}
        />
      </Box>
    </Card>
  );
}
