import { Box, Button, Typography } from "@mui/material";
import { IconEdit } from "@tabler/icons-react";

import { DeactivatedCard } from "../activation/deactivated-card";
import { ReactivationCard } from "../activation/reactivation-card";
import {
  AboutCardEditable,
  Avatar,
  CopyProfileLinkButton,
  CoverEditable,
  ProfileCard,
  ShareTwitterSection,
  SocialLinksSectionEditable,
  UpdateProfileDialog,
} from "../components/profile";
import { WishlistSectionOwner } from "../components/wishlist.onwer";
import { WishlistFrozenNoticeOwner } from "../components/wishlist-frozen-notice.owner";
import { WishlistPageLayout } from "../components/wishlist-page-layout";
import { WishlistTabs } from "../components/wishlist-tabs";
import { WishlistUnactivatedNotice } from "../components/wishlist-unactivated-notice";
import { useEditProfileParams } from "../hooks/use-edit-profile-params";
import { useWishlistData } from "../hooks/use-wishlist-data";

export function WishlistPageOwner() {
  const { wisher, wishlist } = useWishlistData();
  const [openEditProfile, setOpenEditProfile] = useEditProfileParams();

  const isDeactivated = !wisher.activated && !wisher.canActivate;
  const isNewWisher = wisher.activatedStatus === "unactivated";
  const canReactivate = false;

  return (
    <WishlistPageLayout
      header={
        <CoverEditable
          wishlistId={wishlist.id}
          src={wisher.coverUrl}
          alt={`${wishlist.name} cover`}
        />
      }
      left={
        <ProfileCard>
          <WishlistFrozenNoticeOwner
            wisher={wisher}
            sx={{ display: { xs: "none", lg: "block" } }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              gap: 2,
              width: "100%",
            }}
          >
            <Avatar src={wisher.avatarUrl} alt={`${wisher.handle} avatar`} />
            <Box sx={{ display: { xs: "block", lg: "none" }, ml: "auto" }}>
              <ButtonEditProfile onClick={() => setOpenEditProfile(true)} />
            </Box>
          </Box>
          <Box className="notranslate" sx={{ textAlign: { xs: "left", lg: "center" } }}>
            <Typography component="h1" variant="h8">
              {wishlist?.name}
            </Typography>
            <Typography variant="b5" color="neutral.5" sx={{ position: "relative", mt: "2px" }}>
              <span>@{wisher.handle}</span>
              <CopyProfileLinkButton handle={wisher.handle} sx={{ ml: 1 }} />
            </Typography>
          </Box>
          {wisher.bio && (
            <Typography variant="b5" sx={{ textAlign: { xs: "left", lg: "center" } }}>
              {wisher.bio}
            </Typography>
          )}
          <Box sx={{ display: { xs: "none", lg: "block" } }}>
            <ButtonEditProfile onClick={() => setOpenEditProfile(true)} />
          </Box>

          {wisher.canActivate && <WishlistUnactivatedNotice wisher={wisher} />}

          <Box sx={{ display: { xs: "none", lg: "block" } }}>
            <SocialLinksSectionEditable wisher={wisher} />
            <Box mt={{ xs: 3, lg: 4 }} />
            <ShareTwitterSection handle={wisher.handle} enabled={wishlist.surpriseGift.enabled} />
          </Box>
        </ProfileCard>
      }
    >
      {isDeactivated && !canReactivate && (
        <Box my={{ xs: 3, md: 4 }} sx={{ maxWidth: 448 }}>
          <DeactivatedCard isNewWisher={isNewWisher} />
        </Box>
      )}
      {isDeactivated && canReactivate && (
        <Box my={{ xs: 3, md: 4 }} sx={{ maxWidth: 448 }}>
          <ReactivationCard />
        </Box>
      )}

      <Box sx={{ display: { xs: "flex", lg: "none" }, gap: 3, flexDirection: "column" }}>
        <WishlistFrozenNoticeOwner wisher={wisher} />
        <Box />
      </Box>

      <WishlistTabs
        render={({ tab }) => (
          <>
            <Box mt={2} />
            <Box sx={{ display: { xs: tab === "wishes" ? "block" : "none", lg: "block" } }}>
              <WishlistSectionOwner />
            </Box>
            <Box sx={{ display: { xs: tab === "about" ? "block" : "none", lg: "none" } }}>
              <AboutCardEditable wisher={wisher} wishlist={wishlist} />
            </Box>
          </>
        )}
      />

      {openEditProfile && (
        <UpdateProfileDialog
          wisher={wisher}
          wishlist={wishlist}
          onClosed={() => setOpenEditProfile(false)}
        />
      )}
    </WishlistPageLayout>
  );
}

function ButtonEditProfile({ onClick }: { onClick: () => void }) {
  return (
    <Button
      onClick={onClick}
      variant="outlined"
      endIcon={<IconEdit />}
      sx={{
        width: { xs: "inherit", lg: "100%" },
        height: { xs: "40px", lg: "44px" },
        px: { xs: 3, lg: 4 },
        fontSize: { xs: "14px", lg: "16px" },
        lineHeight: "24px",
        fontWeight: 600,
      }}
    >
      Edit Your Profile
    </Button>
  );
}
