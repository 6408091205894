import { Button, type ButtonProps } from "@mui/material";

export function ProfileButton({ sx, ...props }: ButtonProps) {
  return (
    <Button
      size="small"
      sx={{
        fontSize: "14px",
        fontWeight: 600,
        color: "neutral.5",
        padding: "6px 8px",
        borderRadius: "8px",
        "& .MuiButton-startIcon": {
          color: "neutral.7",
          height: "32px",
          width: "32px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          backgroundColor: "neutral.2",
          transition: "all 0.2s ease-in-out",
        },
        "&:hover": {
          color: "neutral.7",
          backgroundColor: "neutral.2",
          "& .MuiButton-startIcon": {
            backgroundColor: "white",
            color: "teal.4",
          },
        },
        ...sx,
      }}
      {...props}
    />
  );
}
