import { Box, IconButton, Typography } from "@mui/material";
import { IconEdit, IconExternalLink } from "@tabler/icons-react";

import type { Wisher } from "~/types";

import { ProfileButton } from "./profile-button";
import { SocialLinks } from "./social-links";
import { UpdateSocialLinksDialog } from "./update-social-links-dialog";

export function SocialLinksSectionEditable({
  wisher,
}: {
  wisher: Pick<Wisher, "id" | "handle" | "socialLinks">;
}) {
  const { id: wisherId, handle, socialLinks } = wisher;

  return (
    <>
      {!socialLinks || socialLinks.length === 0 ? (
        <UpdateSocialLinksDialog
          wisherId={wisherId}
          socialLinks={socialLinks}
          renderControl={({ toggle }) => (
            <ProfileButton onClick={toggle} startIcon={<IconExternalLink size={24} />}>
              Add Social Links
            </ProfileButton>
          )}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          <Typography
            variant="sh3"
            component="div"
            color="neutral.5"
            sx={{ display: "flex", alignItems: "center" }}
          >
            SOCIALS
            <UpdateSocialLinksDialog
              wisherId={wisherId}
              socialLinks={socialLinks}
              renderControl={({ toggle }) => (
                <IconButton size="small" aria-label="Edit links" onClick={toggle} sx={{ ml: 0.5 }}>
                  <IconEdit size={16} />
                </IconButton>
              )}
            />
          </Typography>
          <SocialLinks handle={handle} socialLinks={socialLinks} />
        </Box>
      )}
    </>
  );
}
