import { arrayMove } from "@dnd-kit/sortable";
import { Box, Chip, Stack, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import { Button } from "~/components/button";
import { Routes } from "~/constants/routes";
import { useAuth } from "~/hooks/use-auth";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";
import { CreateCartRequest } from "~/modules/wisher-requests/components/create-cart-request";

import { useCategoriesParam } from "../hooks/use-categories-params";
import { useItemParams } from "../hooks/use-item-params";
import { useWishlistData } from "../hooks/use-wishlist-data";
import { AddNewItemDialog } from "./add-new-item";
import { AddNewItemButton } from "./add-new-item-button";
import { AddWishButton } from "./add-wish-button";
import { CategoriesMenuEditable, CopyCategoriesLinkButton } from "./categories";
import { EditItemDialog } from "./edit-item-dialog";
import { WishlistEmpty } from "./wishlist-empty";
import type { DragEndEvent } from "./wishlist-items-draggable";
import { WishlistItemsDraggable } from "./wishlist-items-draggable";

export function WishlistSectionOwner() {
  const auth = useAuth();

  const { wisher, wishlist } = useWishlistData();

  const [items, setItems] = useState(wishlist.items);

  const [selectedItem, setSelectedItem] = useItemParams(wishlist.items);

  const [selectedCategories, setSelectedCategories] = useCategoriesParam();

  const totalItems = items?.length || 0;
  const filteredItems = useMemo(() => {
    if (!items) return [];
    const selectedCategoriesSet = new Set(selectedCategories);

    return items.filter((item) => {
      if (selectedCategoriesSet.size === 0) return true;
      return item.categories?.some((category) => selectedCategoriesSet.has(category));
    });
  }, [items, selectedCategories]);

  const [submit, submitState] = useWtFetcher({
    onError: (response) => alert(response.error),
  });

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (over && active.id !== over.id && !submitState.isFetching) {
      const activeIndex = filteredItems.findIndex((item) => item.id === active.id);
      const overIndex = filteredItems.findIndex((item) => item.id === over.id);
      const newItems = arrayMove(filteredItems, activeIndex, overIndex);
      // Using state and update the list immediately to make the UX better
      setItems(newItems);
      submit.submit(
        {
          body: JSON.stringify({
            wishlistId: wishlist.id,
            wishlistItems: newItems.map((item) => item.id),
          }),
        },
        {
          method: "POST",
          action: Routes.actions.wisher.wishlist.update(),
        },
      );
    }
  }

  useEffect(() => {
    setItems(wishlist.items || []);
  }, [wishlist.items]);

  if (totalItems === 0) {
    return <WishlistEmpty wisher={wisher} wishlist={wishlist} />;
  }

  return (
    <Box>
      <Box mt={2} />
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <CategoriesMenuEditable
          categories={wishlist.categories || []}
          selectedCategories={selectedCategories}
          onSelectCategories={setSelectedCategories}
          renderControl={({ open, toggle, menuId, controlId }) => {
            const isActive = open || selectedCategories?.length > 0;
            return (
              <Button
                id={controlId}
                variant={isActive ? "contained" : "outlined"}
                aria-controls={open ? menuId : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={toggle}
                sx={{
                  borderWidth: { xs: "1px", lg: "2px" },
                  ":hover": { borderWidth: { xs: "1px", lg: "2px" } },
                  maxHeight: { xs: "36px", lg: "40px" },
                  px: { xs: isActive ? "16px" : "15px", lg: isActive ? "24px" : "22px" },
                  fontSize: "14px",
                  lineHeight: "24px",
                }}
              >
                <span>Categories</span>
                {selectedCategories?.length > 0 && <span> ({selectedCategories.length})</span>}
              </Button>
            );
          }}
        />
        <Stack
          spacing={2}
          direction={{
            xs: "column-reverse",
            sm: "row",
          }}
        >
          {auth.user?.featureFlags.includes("request") ? (
            <CreateCartRequest.Provider>
              <CreateCartRequest.WisherProfileDialog />
              <AddNewItemButton wishlist={wishlist} wisher={wisher} />
            </CreateCartRequest.Provider>
          ) : (
            <AddNewItemDialog
              wishlist={wishlist}
              renderControl={({ toggle }) => <AddWishButton wisher={wisher} onClick={toggle} />}
            />
          )}
        </Stack>
      </Box>
      {selectedCategories.length > 0 && (
        <>
          <Box mt={2} />
          <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "10px" }}>
            <Typography variant="b5" color="neutral.5">
              Found {filteredItems.length} out of {totalItems} Wishes in:{" "}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "12px" }}>
              {selectedCategories.map((category) => (
                <Chip
                  key={category}
                  label={category}
                  variant="outlined"
                  onDelete={() => {
                    setSelectedCategories(selectedCategories.filter((c) => c !== category));
                  }}
                />
              ))}
            </Box>
            <CopyCategoriesLinkButton handle={wisher.handle} categories={selectedCategories} />
          </Box>
        </>
      )}

      <Box mt={3} />

      <WishlistItemsDraggable
        items={filteredItems}
        wisher={wisher}
        disabled={selectedCategories.length > 0}
        onDragEnd={handleDragEnd}
        onClickItem={(item) => setSelectedItem(item.id)}
      />

      {selectedItem && (
        <EditItemDialog
          item={selectedItem}
          wishlist={wishlist}
          onClosed={() => setSelectedItem(undefined)}
        />
      )}
    </Box>
  );
}
