import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import { forwardRef, useState } from "react";

import { TextField } from "~/components/form/text-field";

type ItemCategoriesInputProps = {
  value?: string[];
  onChange?: (value: string[]) => void;
  options?: string[];
  errorMessage?: string;
};

export const ItemCategoriesInput = forwardRef<HTMLInputElement, ItemCategoriesInputProps>(function (
  { value = [], options: defaultOptions = [], onChange, errorMessage },
  ref,
) {
  const [input, setInput] = useState("");
  const [focused, setFocused] = useState(false);

  const [options, setOptions] = useState(defaultOptions);

  const duplicated = value.includes(input.trim());

  function handleAddCategory() {
    const inputValue = input.trim();
    const newOptions = new Set([...options, inputValue]);
    const newValue = new Set([...value, inputValue]);
    setOptions?.([...newOptions]);
    onChange?.([...newValue]);
    setInput("");
  }

  function handleSelectCategory(category: string) {
    const newValue = new Set([...value, category]);
    onChange?.([...newValue]);
  }

  function handleUnSelectCategory(category: string) {
    const newValue = new Set(value.filter((c) => c !== category));
    onChange?.([...newValue]);
  }

  return (
    <TextField.Root fullWidth>
      <TextField.Label>
        <Typography component="span" variant="h5">
          Categorize this Wish (optional)
        </Typography>
      </TextField.Label>
      <Box mt={1} />
      <TextField.Helper sx={{ m: 0 }}>
        <Typography color="neutral.6">
          Organize your wishes to help gifters find what they're looking for while on your wishlist.
        </Typography>
      </TextField.Helper>
      {options.length > 0 && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
          {options.map((option) => (
            <FormControl required key={option}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(_, checked) => {
                      if (checked) {
                        handleSelectCategory(option);
                      } else {
                        handleUnSelectCategory(option);
                      }
                    }}
                    checked={value.includes(option)}
                  />
                }
                label={option}
              />
            </FormControl>
          ))}
        </Box>
      )}
      <Box mt={2} />
      <ClickAwayListener onClickAway={() => setFocused(false)}>
        <Stack spacing={1} direction="row" sx={{ width: "100%", alignItems: "flex-start" }}>
          <Box sx={{ flex: 1, transition: "all ease-in-out 0.2s" }}>
            <TextField.Input
              fullWidth
              placeholder="Add new category"
              autoComplete="off"
              value={input}
              onChange={(event) => {
                const inputValue = event.target.value || "";
                setInput(inputValue);
              }}
              ref={ref}
              onFocus={() => setFocused(true)}
            />
            {(errorMessage || duplicated) && (
              <TextField.Helper error>
                {errorMessage || "This category already exists"}
              </TextField.Helper>
            )}
          </Box>
          <Button
            type="button"
            variant="text"
            disabled={input.trim() === "" || duplicated}
            onClick={handleAddCategory}
            sx={{ display: focused ? "block" : "none", px: 1 }}
          >
            Add
          </Button>
        </Stack>
      </ClickAwayListener>
    </TextField.Root>
  );
});

ItemCategoriesInput.displayName = "ItemCategoriesInput";
