import { Box, IconButton, Typography } from "@mui/material";
import { IconCheck, IconEdit } from "@tabler/icons-react";
import { Controller, useForm } from "react-hook-form";
import { useToggle } from "usehooks-ts";

import { TextField } from "~/components/form/text-field";
import { Routes } from "~/constants/routes";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";

import { ProfileButton } from "./profile-button";

type BioEditableProps = {
  bio: string;
  wishlistId: string;
};

export function BioEditable({ bio, wishlistId }: BioEditableProps) {
  const [isEditing, toggle] = useToggle(false);

  const form = useForm({
    defaultValues: {
      bio,
    },
  });

  const [submit, submitState] = useWtFetcher({
    onSuccess: () => {
      toggle();
    },
    onError: (response) => {
      alert(response.error);
    },
  });

  function handleSubmit(data: { bio: string }) {
    submit.submit(
      { body: JSON.stringify({ bio: data.bio, wishlistId }) },
      {
        method: "POST",
        action: Routes.actions.wisher.wishlist.update(),
      },
    );
  }

  if (!isEditing) {
    return bio ? (
      <Typography variant="b5">
        {bio}{" "}
        <IconButton aria-label="Edit bio" sx={{ color: "neutral.5" }} size="small" onClick={toggle}>
          <IconEdit />
        </IconButton>
      </Typography>
    ) : (
      <Typography variant="b5">
        <ProfileButton onClick={toggle} startIcon={<IconEdit size={24} />}>
          Add a message for your fans
        </ProfileButton>
      </Typography>
    );
  }

  return (
    <Box
      component="form"
      onSubmit={form.handleSubmit(handleSubmit)}
      sx={{ width: "100%", display: "flex", alignItems: "flex-start" }}
    >
      <Controller
        name="bio"
        control={form.control}
        rules={{
          maxLength: {
            value: 30,
            message: "Bio must be less than 30 characters",
          },
        }}
        render={({ field, fieldState }) => (
          <TextField.Root fullWidth>
            <TextField.Input {...field} placeholder="Add a message" autoComplete="off" multiline />
            {fieldState.error && <TextField.Helper>{fieldState.error?.message}</TextField.Helper>}
          </TextField.Root>
        )}
      />
      <IconButton
        aria-label="Save nessage"
        size="small"
        sx={{ ml: 0.5 }}
        type="submit"
        disabled={submitState.isFetching}
      >
        <IconCheck />
      </IconButton>
    </Box>
  );
}
