import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import type { FormEvent, ReactNode } from "react";
import { useToggle } from "usehooks-ts";

import { Dialog } from "~/components/dialog";
import { Routes } from "~/constants/routes";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";

type DeleteItemDialogProps = {
  itemId: string;
  renderControl: ({ toggle }: { toggle: () => void }) => ReactNode;
  onDeleted?: () => void;
};

export function DeleteItemDialog({ itemId, renderControl, onDeleted }: DeleteItemDialogProps) {
  const [open, toggle] = useToggle(false);

  const [submit, submitState] = useWtFetcher({
    onSuccess: () => {
      toggle();
      onDeleted?.();
    },
    onError: (response) => alert(response.error),
  });

  function handleDelete(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    event.stopPropagation(); // prevent submitting the parent form (update item)
    submit.submit(
      { wishlistItem: itemId },
      {
        method: "POST",
        action: Routes.actions.wisher.wishlist.item.delete(),
      },
    );
  }

  return (
    <>
      {renderControl({ toggle })}
      <Dialog.Root variant="alert" maxWidth="xs" open={open}>
        <Dialog.Title>Delete Wish</Dialog.Title>
        <Dialog.Content sx={{ textAlign: "center" }}>
          Are you sure you want to delete this wish?
        </Dialog.Content>
        <form onSubmit={handleDelete}>
          <Dialog.Actions>
            <Button variant="outlined" onClick={toggle}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="error"
              type="submit"
              loading={submitState.isFetching}
            >
              <span>Delete</span>
            </LoadingButton>
          </Dialog.Actions>
        </form>
      </Dialog.Root>
    </>
  );
}
