import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import type { TextFieldProps } from "@mui/material";
import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { IconX } from "@tabler/icons-react";
import { useState } from "react";
import { type Control, type FieldValues, type Path, useController } from "react-hook-form";

import { TextField } from "~/components/form/text-field";
import { TAG_VALIDATION_REGEX } from "~/constants/regex";

const MAXIMUM_TAGS = 8;

type AddTagsInputProps<T extends FieldValues> = TextFieldProps & {
  control: Control<T>;
  name: Path<T>;
};

export function AddTagsInput<T extends FieldValues>({ control, name }: AddTagsInputProps<T>) {
  const [inputTagText, setInputTagText] = useState("");
  const [focused, setFocused] = useState(false);

  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });

  function handleDeleteTag(index: number) {
    onChange((value as string[]).filter((_tag, index_) => index_ !== index));
  }

  function handleAddTag() {
    if (inputTagText !== "" && !value.includes(inputTagText) && value.length < MAXIMUM_TAGS) {
      const newTags = [...value, inputTagText];
      onChange(newTags);
      setInputTagText("");
    }
  }

  return (
    <Stack spacing={2}>
      <TextField.Root fullWidth>
        <TextField.Label>
          <Typography component="span" variant="h5">
            Profile Tags
          </Typography>
        </TextField.Label>
        <Box mt={1} />
        <TextField.Helper sx={{ m: 0 }}>
          <Typography color="neutral.6">
            Avoid using explicit language that may be deemed inappropriate, keep your keywords
            non-suggestive.
          </Typography>
        </TextField.Helper>
        <Box mt={1} />
        <ClickAwayListener onClickAway={() => setFocused(false)}>
          <Stack spacing={1} direction="row" sx={{ width: "100%", alignItems: "flex-start" }}>
            <Box sx={{ flex: 1, transition: "all ease-in-out 0.2s" }}>
              <TextField.Input
                fullWidth
                disabled={value.length >= MAXIMUM_TAGS}
                value={inputTagText}
                placeholder={
                  value.length > 0
                    ? `${MAXIMUM_TAGS - value.length} keywords remaining`
                    : "Add up to 8 keywords"
                }
                error={value.includes(inputTagText)}
                onChange={(event) => {
                  if (
                    event.target.value === "" ||
                    (TAG_VALIDATION_REGEX.test(event.target.value) &&
                      event.target.value.length <= 40)
                  ) {
                    setInputTagText(event.target.value);
                  }
                }}
                onFocus={() => setFocused(true)}
              />
              {value.includes(inputTagText) && (
                <TextField.Helper error>Duplicate tags.</TextField.Helper>
              )}
            </Box>
            <Button
              disabled={
                inputTagText === "" || value.includes(inputTagText) || value.length >= MAXIMUM_TAGS
              }
              type="button"
              variant="text"
              onClick={handleAddTag}
              sx={{ display: focused ? "block" : "none" }}
            >
              Add
            </Button>
          </Stack>
        </ClickAwayListener>
      </TextField.Root>
      {value.length > 0 && (
        <Stack direction="row" sx={{ flexWrap: "wrap", gap: 1, my: 2 }}>
          {value.map((tag: string, index: number) => (
            <Chip
              key={tag}
              variant="outlined"
              label={tag}
              deleteIcon={<IconX size={14} />}
              onDelete={() => handleDeleteTag(index)}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
}
