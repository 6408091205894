import { Box, IconButton, ListItemText, MenuItem, Typography } from "@mui/material";
import {
  IconCheck,
  IconEdit,
  IconSquare,
  IconSquareCheckFilled,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import type { FormEvent } from "react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { TextField } from "~/components/form/text-field";
import { Routes } from "~/constants/routes";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";

type CategoryMenuItemEditableProps = {
  category: string;
  selected?: boolean;
  onSelect?: () => void;
  onUpdated?: (newCategory: string) => void;
  onDeleted?: () => void;
};

export function CategoryMenuItemEditable({
  category,
  selected,
  onSelect,
  onUpdated,
  onDeleted,
}: CategoryMenuItemEditableProps) {
  const [editing, setEditing] = useState(false);

  const form = useForm({
    defaultValues: { category },
  });

  const [submitUpdate, submitUpdateState] = useWtFetcher({
    onSuccess: () => {
      const data = form.getValues();
      onUpdated?.(data.category);
      setEditing(false);
    },
    onError: (response) => alert(response.error),
  });

  const [submitDelete, submitDeleteState] = useWtFetcher({
    onSuccess: () => {
      onDeleted?.();
    },
    onError: (response) => alert(response.error),
  });

  function handleCancel() {
    setEditing(false);
    form.reset();
  }

  function handleSubmitUpdate(data: { category: string }) {
    if (data.category?.trim()) {
      submitUpdate.submit(
        {
          category,
          update: data.category,
        },
        {
          method: "POST",
          action: Routes.actions.wisher.wishlist.category.update(),
        },
      );
    } else {
      handleCancel();
    }
  }

  function handleSubmitDelete(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    submitDelete.submit(
      {
        deleteCategory: category,
      },
      {
        method: "POST",
        action: Routes.actions.wisher.wishlist.category.delete(),
      },
    );
  }

  return (
    <MenuItem
      selected={selected}
      disableTouchRipple
      onKeyDown={(event) => {
        if (editing) {
          event.stopPropagation();
        }
      }}
      sx={{ cursor: "default" }}
    >
      <IconButton
        color="inherit"
        size="small"
        sx={{ ml: 2 }}
        onClick={onSelect}
        aria-label="Select category"
        title="Select category"
      >
        {selected ? <IconSquareCheckFilled /> : <IconSquare />}
      </IconButton>
      <ListItemText title={category}>
        {editing ? (
          <Controller
            name="category"
            control={form.control}
            render={({ field, fieldState }) => (
              <TextField.Root>
                <TextField.Input sx={{ "& .MuiInputBase-input": { py: 0.5 } }} {...field} />
                {fieldState.error && (
                  <TextField.Helper error>{fieldState.error.message}</TextField.Helper>
                )}
              </TextField.Root>
            )}
          />
        ) : (
          <Typography variant="inherit" noWrap>
            {category}
          </Typography>
        )}
      </ListItemText>
      {editing ? (
        <Box
          sx={{ ml: 2 }}
          onSubmit={form.handleSubmit(handleSubmitUpdate)}
          method="POST"
          component="form"
        >
          <IconButton
            size="small"
            type="submit"
            disabled={submitUpdateState.isFetching}
            aria-label="Save category"
            title="Save category"
          >
            <IconCheck size={20} />
          </IconButton>
          <IconButton
            size="small"
            onClick={handleCancel}
            disabled={submitUpdateState.isFetching}
            aria-label="Cancel edit category"
            title="Cancel edit category"
          >
            <IconX size={20} />
          </IconButton>
        </Box>
      ) : (
        <Box sx={{ ml: 2, display: "flex" }}>
          <IconButton
            size="small"
            onClick={() => setEditing(true)}
            disabled={submitDeleteState.isFetching}
            aria-label="Edit category"
            title="Edit category"
          >
            <IconEdit size={20} />
          </IconButton>
          <form method="POST" onSubmit={handleSubmitDelete}>
            <IconButton
              size="small"
              type="submit"
              disabled={submitDeleteState.isFetching}
              aria-label="Delete category"
              title="Delete category"
            >
              <IconTrash size={20} />
            </IconButton>
          </form>
        </Box>
      )}
    </MenuItem>
  );
}
