import { Box, Typography } from "@mui/material";

import type { SocialLink } from "~/types";

import { SocialLinks } from "./social-links";

export function SocialLinksSection({
  handle,
  socialLinks,
}: {
  handle: string;
  socialLinks: SocialLink[];
}) {
  if (!socialLinks || socialLinks.length === 0) return <></>;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: { xs: "column", md: "row", lg: "column" },
        alignItems: "center",
        flexWrap: "wrap",
        gap: 2,
      }}
    >
      <Typography variant="sh3" component="p" color="neutral.5">
        SOCIALS
      </Typography>
      <SocialLinks handle={handle} socialLinks={socialLinks} />
    </Box>
  );
}
