import { Box, ButtonBase, IconButton, Typography } from "@mui/material";
import { IconPlus } from "@tabler/icons-react";

import { CropImageDialog } from "~/components/crop-image-dialog";
import { CameraSparkleIcon } from "~/components/icons/camera-sparkle-icon";

import { COVER_DIMENSIONS } from "../../constants";

type CoverInputProps = {
  defaultValue?: string;
  value?: Blob;
  onChange: (value: Blob) => void;
};

export function CoverInput({ defaultValue, value, onChange }: CoverInputProps) {
  return (
    <CropImageDialog
      dimensions={COVER_DIMENSIONS}
      title="Crop cover image"
      onCropped={(file) => {
        onChange?.(file);
      }}
      renderControl={({ onSelect }) => {
        const coverUrl = value ? URL.createObjectURL(value) : defaultValue;
        return (
          <Box
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "118px",
              width: "100%",
              borderRadius: { xs: 0, lg: "8px" },
              backgroundColor: "neutral.4",
              overflow: "hidden",
            }}
          >
            {coverUrl ? (
              <>
                <Box
                  sx={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    backgroundImage: `url(${coverUrl})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    filter: "brightness(0.8)",
                  }}
                />
                <IconButton
                  sx={{
                    width: 64,
                    height: 64,
                    color: "white",
                    backgroundColor: "#0B0F10b3",
                    "&:hover": {
                      backgroundColor: "#0B0F10cc",
                    },
                  }}
                  onClick={onSelect}
                >
                  <CameraSparkleIcon width="60%" height="60%" />
                </IconButton>
              </>
            ) : (
              <ButtonBase
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                  padding: 1,
                  borderRadius: 1,
                }}
                onClick={onSelect}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: { xs: 26, lg: 32 },
                    height: { xs: 26, lg: 32 },
                    background: "#fff",
                    borderRadius: "50%",
                    color: "gray.4",
                    padding: "4px",
                  }}
                >
                  <IconPlus size="100%" />
                </Box>
                <Typography variant="h7" color="white">
                  Add a Cover Image (Optional)
                </Typography>
              </ButtonBase>
            )}
          </Box>
        );
      }}
    />
  );
}
