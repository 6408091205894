import { z } from "zod";

import { URL_VALIDATION_REGEX } from "~/constants/regex";

import { HandleSchema } from "../wisher";
import { MAX_BIO_LENGTH } from "./constants";

export const AddToCartSchema = z
  .object({
    type: z.string(),
    itemId: z.string(),
    currency: z.string(),
    interval: z.number().optional(),
    contributionAmount: z.number().optional(),
    goToCart: z.boolean().optional(),
  })
  .refine(
    (data) => {
      if (data.type === "crowdfunding") {
        return data.contributionAmount && data.contributionAmount > 0;
      }
      return true;
    },
    {
      message: "Amount must be greater than 0",
      path: ["contributionAmount"],
    },
  );

export const UpdateProfileSchema = z.object({
  wisherId: z.string(),
  wishlistId: z.string(),
  handle: HandleSchema.optional(),
  aliasName: z.string().trim().nonempty("Display Name is required").optional(),
  wishlistName: z.string().trim().nonempty("Wishlist Name is required").optional(),
  bio: z.string().trim().max(MAX_BIO_LENGTH).optional(),
  tags: z
    .preprocess((value) => {
      try {
        if (Array.isArray(value)) return value;
        return JSON.parse(String(value));
      } catch {
        return [];
      }
    }, z.array(z.string().trim().nonempty()))
    .optional(),
  coverImage: z.any().optional(),
  avatarImage: z.any().optional(),
});

export const UpdateSocialLinksSchema = z.object({
  wisherId: z.string(),
  socialLinks: z.array(
    z.object({
      platform: z.string().nonempty({ message: "Platform is required" }),
      url: z
        .string()
        .trim()
        .nonempty({ message: "Link is required" })
        .regex(
          URL_VALIDATION_REGEX,
          'The link you used is invalid: make sure your URL starts with "https://" and includes a valid domain suffix like ".com"',
        ),
      disabled: z.boolean().optional(),
    }),
  ),
});

export const AddNewItemSchema = z.object({
  wishlist: z.string(),
  type: z.enum(["crowdfunding", "item", "subscription"]),
  itemName: z.string().nonempty({ message: "Wish name is required" }),
  price: z
    .preprocess((value) => Number.parseInt(String(value)), z.number())
    .refine((value) => value > 0, { message: "Price must be greater than 0" }),
  currency: z.string().nonempty({ message: "Currency is required" }),
  url: z.string().trim().optional(),
  itemImage: z.string().optional(),
  imageCrop: z.any(),
  image: z.any(),
  categories: z.array(z.string()).optional(),
  tags: z.array(z.string()).optional(),
  subscriptionIntervals: z
    .array(z.preprocess((value) => Number.parseInt(String(value)), z.number()))
    .optional(),
  repeatPurchases: z.preprocess((v) => v === true || v === "true", z.boolean()).optional(),
});

export const UpdateItemSchema = z.object({
  id: z.string(),
  type: z.enum(["crowdfunding", "item", "subscription"]).optional(),
  itemName: z.string().nonempty({ message: "Wish name is required" }).optional(),
  price: z
    .preprocess((value) => Number.parseInt(String(value)), z.number())
    .refine((value) => value > 0, { message: "Price must be greater than 0" })
    .optional(),
  currency: z.string().nonempty({ message: "Currency is required" }).optional(),
  url: z.string().trim().optional(),
  itemImage: z.string().optional(),
  image: z.any(),
  categories: z.array(z.string()).optional(),
  tags: z.array(z.string()).optional(),
  subscriptionIntervals: z
    .array(z.preprocess((value) => Number.parseInt(String(value)), z.number()))
    .optional(),
  repeatPurchases: z.preprocess((v) => v === true || v === "true", z.boolean()).optional(),
});
