import { useMemo } from "react";

import { useSearchParamsNoReload } from "~/hooks/use-search-params-no-reload";
import type { WishlistItem } from "~/types";

export function useItemParams(items: WishlistItem[]) {
  const [searchParams, setSearchParams] = useSearchParamsNoReload();

  const selectedItem = useMemo(() => {
    const itemId = searchParams.get("item");
    if (!itemId) return undefined;
    return items.find((item) => item.id === itemId) ?? undefined;
  }, [items, searchParams]);

  function setSelectedItem(itemId?: string) {
    const newParams = new URLSearchParams(searchParams);
    if (itemId) {
      newParams.set("item", itemId);
    } else {
      newParams.delete("item");
    }
    setSearchParams(newParams);
  }

  return [selectedItem, setSelectedItem] as const;
}
