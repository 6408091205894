import { useMemo } from "react";

import { useSearchParamsNoReload } from "~/hooks/use-search-params-no-reload";

export function useSendSurpriseParams() {
  const [searchParams, setSearchParams] = useSearchParamsNoReload();

  const params = useMemo(() => {
    const open = searchParams.has("surprise");
    const amount = searchParams.get("amount") || undefined;
    const note = searchParams.get("note") || "";
    return { open, amount, note };
  }, [searchParams]);

  function setParams({ open, amount, note }: { open: boolean; amount?: string; note?: string }) {
    const newParams = new URLSearchParams(searchParams);
    if (open) {
      newParams.set("surprise", "");
    } else {
      newParams.delete("surprise");
    }
    if (amount) {
      newParams.set("amount", amount);
    } else {
      newParams.delete("amount");
    }
    if (note) {
      newParams.set("note", note);
    } else {
      newParams.delete("note");
    }
    setSearchParams(newParams);
  }

  return [params, setParams] as const;
}
