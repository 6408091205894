import { useRouteLoaderData } from "@remix-run/react";

import { RouteIds } from "~/constants/routes";
import { useAuth } from "~/hooks/use-auth";
import type { Wisher, Wishlist } from "~/types";

export function useWishlistData() {
  const auth = useAuth();
  const { wisher, wishlist, gifterToWisherRate } = useRouteLoaderData(
    RouteIds.public.wishlist(),
  ) as {
    wisher: Wisher;
    wishlist: Wishlist;
    gifterToWisherRate: number;
  };

  const isWishlistOwner = auth.wisher?.id === wisher.id;

  const canSendTip =
    wishlist?.surpriseGift.enabled && !wisher.frozen && wisher.activated && !isWishlistOwner;

  const showWishlist = (!wisher.frozen && wisher.activated) || auth.user?.admin || isWishlistOwner;

  return { wisher, wishlist, gifterToWisherRate, isWishlistOwner, showWishlist, canSendTip };
}
