import PeopleIcon from "@mui/icons-material/People";
import { Box } from "@mui/material";

export function ItemCrowdfundingIndicator() {
  return (
    <Box
      sx={{
        background: "linear-gradient(180deg, #6FDFFF 0%, #50ACFF 100%)",
        fontSize: "12px",
        height: "20px",
        borderRadius: "20px",
        padding: "4px 8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "4px",
        fontWeight: 700,
        fontFamily: "Nunito",
      }}
    >
      Crowdfund <PeopleIcon fontSize="small" />
    </Box>
  );
}
