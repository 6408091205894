import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Box, ButtonBase, Typography } from "@mui/material";
import { serialize } from "object-to-formdata";
import { Controller, useForm } from "react-hook-form";
import type { z } from "zod";

import { CropImageDialog } from "~/components/crop-image-dialog";
import { TextField } from "~/components/form/text-field";
import { useAuth } from "~/hooks/use-auth";
import { useOrigin } from "~/hooks/use-origin";
import { usePresentmentCurrency } from "~/modules/currency";
import type { Wishlist } from "~/types";

import { ITEM_IMAGE_DIMENSIONS } from "../../constants";
import { useAddItem } from "../../hooks/use-add-item";
import { AddNewItemSchema } from "../../schemas";
import { ItemCategoriesInput } from "../item-categories-input";
import { ItemImageSelect } from "../item-image-select";
import { ItemTagsInput } from "../item-tags-input";
import { ItemTypeInput } from "../item-type-input";

type AddNewItemFormProps = {
  wishlist: Wishlist;
  onSuccess?: () => void;
};

export function AddNewItemForm({ onSuccess, wishlist }: AddNewItemFormProps) {
  const currency = usePresentmentCurrency("USD");
  const { user } = useAuth();
  const origin = useOrigin();

  const form = useForm<z.infer<typeof AddNewItemSchema>>({
    resolver: zodResolver(AddNewItemSchema),
    defaultValues: {
      wishlist: wishlist.id,
      type: "item",
      currency,
      categories: [],
      tags: [],
    },
  });

  const [submit, submitState] = useAddItem({
    onSuccess: () => {
      onSuccess?.();
      form.reset();
    },
  });

  async function handleSubmit(data: z.infer<typeof AddNewItemSchema>) {
    await submit(serialize(data, { allowEmptyArrays: true }));
  }

  const defaultItemImages = [
    `${origin}/data/images/itemImages/piggy_bank.png`,
    `${origin}/data/images/itemImages/bow.png`,
    `${origin}/data/images/itemImages/shopping.png`,
  ];

  const imageFile = form.watch("image");

  return (
    <Box component="form" onSubmit={form.handleSubmit(handleSubmit)} autoComplete="off">
      <Typography variant="h6">Wish Information</Typography>
      <Box mt={2} />
      <Controller
        name="itemName"
        control={form.control}
        defaultValue=""
        render={({ field, fieldState }) => (
          <TextField.Root fullWidth>
            <TextField.Label>Wish Name</TextField.Label>
            <TextField.Input
              placeholder="Give the wish a name"
              autoFocus
              autoComplete="off"
              {...field}
            />
            {fieldState.error && (
              <TextField.Helper error>{fieldState.error.message}</TextField.Helper>
            )}
          </TextField.Root>
        )}
      />
      <Box mt={2} />
      <Controller
        name="price"
        control={form.control}
        defaultValue={0}
        render={({ field, fieldState }) => (
          <TextField.Root fullWidth>
            <TextField.Label>Price</TextField.Label>
            <TextField.InputMoney {...field} currency={currency} autoComplete="off" />
            {fieldState.error && (
              <TextField.Helper error>{fieldState.error.message}</TextField.Helper>
            )}
            <TextField.Helper>
              Don't forget to add to the total to cover shipping and tax.
            </TextField.Helper>
          </TextField.Root>
        )}
      />
      <Box mt={2} />
      <Controller
        name="url"
        control={form.control}
        render={({ field, fieldState }) => (
          <TextField.Root fullWidth>
            <TextField.Label>URL (optional)</TextField.Label>
            <TextField.Input placeholder="Paste a product URL here" autoComplete="off" {...field} />
            {fieldState.error && (
              <TextField.Helper error>{fieldState.error.message}</TextField.Helper>
            )}
          </TextField.Root>
        )}
      />
      <Box mt={2} />
      {!imageFile && (
        <Controller
          name="itemImage"
          control={form.control}
          defaultValue={defaultItemImages[0]}
          render={({ field }) => (
            <ItemImageSelect
              noCrop
              onSelected={(image) => field.onChange(image.url)}
              images={defaultItemImages}
            />
          )}
        />
      )}

      <Controller
        name="image"
        control={form.control}
        render={({ field }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {imageFile && (
              <img
                src={URL.createObjectURL(imageFile as Blob)}
                style={{ width: "161px" }}
                alt="product"
              />
            )}
            <CropImageDialog
              dimensions={ITEM_IMAGE_DIMENSIONS}
              title="Crop wish image"
              onCropped={(file) => {
                field.onChange(file);
                form.setValue("itemImage", undefined);
              }}
              renderControl={({ onSelect }) => {
                return (
                  <Typography
                    variant="b4"
                    sx={{ textDecoration: "underline", mt: 0.5 }}
                    component={ButtonBase}
                    onClick={onSelect}
                  >
                    Upload Custom Photo
                  </Typography>
                );
              }}
            />
          </Box>
        )}
      />

      <Box mt={2} />
      <ItemTypeInput control={form.control} />
      <Box mt={3} />
      <Typography variant="h7" color="neutral.7">
        Publish
      </Typography>
      <Box mt={2} />
      <Controller
        name="categories"
        control={form.control}
        render={({ field, fieldState }) => (
          <ItemCategoriesInput
            {...field}
            errorMessage={fieldState.error?.message}
            options={wishlist.categories}
          />
        )}
      />
      {user?.featureFlags?.includes("search") && (
        <>
          <Box mt={3}>
            <Controller
              name="tags"
              control={form.control}
              render={({ field, fieldState }) => (
                <ItemTagsInput {...field} errorMessage={fieldState.error?.message} />
              )}
            />
          </Box>
        </>
      )}
      <Box mt={4} />
      <LoadingButton
        variant="contained"
        color="primary"
        type="submit"
        fullWidth
        loading={submitState.isFetching}
      >
        <span>Add Wish</span>
      </LoadingButton>
    </Box>
  );
}
