import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Box, Button, ButtonBase, Typography } from "@mui/material";
import { useRevalidator } from "@remix-run/react";
import pick from "lodash/pick";
import { serialize } from "object-to-formdata";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import type { z } from "zod";

import { CropImageDialog } from "~/components/crop-image-dialog";
import { Dialog } from "~/components/dialog";
import { TextField } from "~/components/form/text-field";
import { useAuth } from "~/hooks/use-auth";
import { usePresentmentCurrency } from "~/modules/currency";
import type { Wishlist, WishlistItem } from "~/types";
import type { WishlistItemPresentmentType } from "~/types/wishlist";
import { checkError } from "~/utils/fetch";

import { ITEM_IMAGE_DIMENSIONS } from "../constants";
import { UpdateItemSchema } from "../schemas";
import { DeleteItemDialog } from "./delete-item-dialog";
import { ItemCategoriesInput } from "./item-categories-input";
import { ItemTagsInput } from "./item-tags-input";
import { ItemTypeInput } from "./item-type-input";

type EditItemDialogProps = {
  item: WishlistItem;
  wishlist: Wishlist;
  onClosed?: () => void;
};

export function EditItemDialog({ item, wishlist, onClosed }: EditItemDialogProps) {
  const [open, setOpen] = useState(false);

  function handleClose() {
    setOpen(false);
    // Waiting for the animation to finish before navigating
    setTimeout(() => {
      onClosed?.();
    }, 200);
  }

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <Dialog.Root maxWidth="xs" variant="normal" open={open} onClose={handleClose} fullWidth>
      <Dialog.Title>Edit Wish</Dialog.Title>
      <Dialog.Content>
        <EditItemForm
          item={item}
          wishlist={wishlist}
          onUpdated={handleClose}
          onDeleted={handleClose}
        />
      </Dialog.Content>
    </Dialog.Root>
  );
}

type EditItemFormProps = {
  item: WishlistItem;
  wishlist: Wishlist;
  onUpdated: () => void;
  onDeleted: () => void;
};

function useEditItem({ onSuccess }: { onSuccess: () => any }) {
  const revalidator = useRevalidator();
  const [submitState, setSubmitState] = useState({ isFetching: false });

  async function submit(id: string, form: any) {
    try {
      setSubmitState({ isFetching: true });

      const response = await fetch(`${window.env.REACT_APP_BASE_URL}/api/wishlistItems/${id}`, {
        method: "PATCH",
        credentials: "include",
        body: form,
      });
      await checkError(response);

      revalidator.revalidate();
      onSuccess();
    } catch (error: any) {
      console.error(error);
      alert(error?.message || "Unknown error");
    } finally {
      setSubmitState({ isFetching: false });
    }
  }

  return [submit, submitState] as const;
}

function EditItemForm({ item, wishlist, onUpdated, onDeleted }: EditItemFormProps) {
  const { user } = useAuth();
  const currency = usePresentmentCurrency("USD");
  const form = useForm<z.infer<typeof UpdateItemSchema>>({
    resolver: zodResolver(UpdateItemSchema),
    defaultValues: {
      id: item.id,
      currency,
      type: item.type as WishlistItemPresentmentType,
      categories: item.categories || [],
      tags: item.tags,
      itemName: item.title,
      price: item.price.amount,
      itemImage: item.thumbnail,
      url: item.url,
      repeatPurchases: item.repeatPurchases,
      subscriptionIntervals: item.subscriptionIntervals,
    },
  });

  const [submit, submitState] = useEditItem({
    onSuccess: () => {
      onUpdated?.();
    },
  });

  const dirtyFields = form.formState.dirtyFields;

  async function handleSubmit(data: z.infer<typeof UpdateItemSchema>) {
    const updatedData = pick(data, ["id", ...Object.keys(dirtyFields)]);
    await submit(data.id, serialize(updatedData, { allowEmptyArrays: true }));
  }

  const itemImage = form.watch("itemImage");

  return (
    <Box component="form" onSubmit={form.handleSubmit(handleSubmit)}>
      <Controller
        name="image"
        control={form.control}
        render={({ field }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img
              src={field.value ? URL.createObjectURL(field.value as Blob) : itemImage}
              style={{ width: "161px" }}
              alt="product"
            />
            <CropImageDialog
              dimensions={ITEM_IMAGE_DIMENSIONS}
              title="Crop wish image"
              onCropped={(file) => {
                field.onChange(file);
                form.setValue("itemImage", undefined);
              }}
              renderControl={({ onSelect }) => {
                return (
                  <Typography
                    variant="b4"
                    sx={{ textDecoration: "underline", mt: 0.5 }}
                    component={ButtonBase}
                    onClick={onSelect}
                  >
                    Upload Custom Photo
                  </Typography>
                );
              }}
            />
          </Box>
        )}
      />

      <Box mt={2} />
      <Controller
        name="itemName"
        control={form.control}
        render={({ field, fieldState }) => (
          <TextField.Root fullWidth>
            <TextField.Label>Wish Name</TextField.Label>
            <TextField.Input placeholder="Give the wish a name" autoFocus {...field} />
            {fieldState.error && (
              <TextField.Helper error>{fieldState.error.message}</TextField.Helper>
            )}
          </TextField.Root>
        )}
      />
      <Box mt={2} />
      <Controller
        name="price"
        control={form.control}
        render={({ field, fieldState }) => (
          <TextField.Root fullWidth>
            <TextField.Label>Price</TextField.Label>
            <TextField.InputMoney {...field} currency={currency} />
            {fieldState.error && (
              <TextField.Helper error>{fieldState.error.message}</TextField.Helper>
            )}
            <TextField.Helper>
              Don't forget to add to the total to cover shipping and tax.
            </TextField.Helper>
          </TextField.Root>
        )}
      />
      <Box mt={2} />
      <Controller
        name="url"
        control={form.control}
        render={({ field, fieldState }) => (
          <TextField.Root fullWidth>
            <TextField.Label>URL (optional)</TextField.Label>
            <TextField.Input placeholder="Paste a product URL here" {...field} />
            {fieldState.error && (
              <TextField.Helper error>{fieldState.error.message}</TextField.Helper>
            )}
          </TextField.Root>
        )}
      />

      <Box mt={2} />
      <ItemTypeInput control={form.control} />
      <Box mt={3} />
      <Typography variant="h7" color="neutral.7">
        Publish
      </Typography>
      <Box mt={2} />
      <Controller
        name="categories"
        control={form.control}
        render={({ field, fieldState }) => (
          <ItemCategoriesInput
            {...field}
            errorMessage={fieldState.error?.message}
            options={wishlist.categories}
          />
        )}
      />
      {user?.featureFlags?.includes("search") && (
        <Box mt={3}>
          <Controller
            name="tags"
            control={form.control}
            render={({ field, fieldState }) => (
              <ItemTagsInput {...field} errorMessage={fieldState.error?.message} />
            )}
          />
        </Box>
      )}
      <Box mt={4} />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <DeleteItemDialog
          itemId={item.id}
          onDeleted={onDeleted}
          renderControl={({ toggle }) => <Button onClick={toggle}>Delete Wish</Button>}
        />
      </Box>
      <Box mt={2} />
      <LoadingButton
        variant="contained"
        color="primary"
        type="submit"
        fullWidth
        loading={submitState.isFetching}
      >
        <span>Update Wish</span>
      </LoadingButton>
    </Box>
  );
}
