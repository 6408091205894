import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import { useNavigate } from "@remix-run/react";
import { type ReactNode, useState } from "react";

import { SplitButton } from "~/components/button";
import { SparklesFilledIcon } from "~/components/icons";
import { MoneyIcon } from "~/components/icons/money-icon";
import { Routes } from "~/constants/routes";
import { useAuth } from "~/hooks/use-auth";
import { CreateCartRequest } from "~/modules/wisher-requests/components/create-cart-request";
import type { Wisher, Wishlist } from "~/types";

import { useCommunityNotice } from "../hooks/use-notices";
import { AddNewItemDialog } from "./add-new-item";

type AddNewItemButtonProps = {
  wishlist: Wishlist;
  wisher: Wisher;
};

type MenuItemContentProps = {
  icon: ReactNode;
  title: string;
  description: string;
  label?: {
    title: string;
    icon: ReactNode;
  };
};

function MenuItemContent({ icon, title, description, label }: MenuItemContentProps) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ display: "flex", pr: 1 }}>{icon}</Box>
      <Box sx={{ minWidth: 182 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h5" color="gray.7">
            {title}
          </Typography>
          {label && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                ml: 1,
                px: 1.5,
                borderRadius: 1000,
                bgcolor: "green.2",
              }}
            >
              <Typography variant="sh2">{label.title}</Typography>
              {label.icon}
            </Box>
          )}
        </Box>
        <Typography component="p" variant="b3" color="gray.5">
          {description}
        </Typography>
      </Box>
    </Box>
  );
}

export function AddNewItemButton({ wishlist, wisher }: AddNewItemButtonProps) {
  const auth = useAuth();
  const communityNotice = useCommunityNotice();
  const navigate = useNavigate();
  const communityNoticeUrl = Routes.wisher.communityNotice({ username: wisher.handle });
  const { setValues } = CreateCartRequest.useCreateCartRequest();
  const [anchorElement, setAnchorElement] = useState<undefined | HTMLElement>();

  function handleCreateNewWish(toggle: () => void) {
    setAnchorElement(undefined);
    if (communityNotice) {
      navigate(communityNoticeUrl, { preventScrollReset: true });
    } else {
      toggle();
    }
  }

  function handleCreateNewRequest() {
    setAnchorElement(undefined);
    setValues({ step: "create" });
  }

  return (
    <>
      <SplitButton
        size="md"
        endIcon={<ExpandMoreIcon sx={{ ml: -1 }} />}
        onClick={(event: any) => setAnchorElement(event.currentTarget)}
        id="add-new-item-button"
        aria-controls={anchorElement ? "add-new-item-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={anchorElement ? "true" : undefined}
      >
        Create New
      </SplitButton>

      <Menu
        id="add-new-item-menu"
        keepMounted
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: "right",
        }}
        open={!!anchorElement}
        onClose={() => setAnchorElement(undefined)}
        MenuListProps={{
          "aria-labelledby": "add-new-item-button",
        }}
      >
        {/* Add Wish Button */}
        <AddNewItemDialog
          wishlist={wishlist}
          renderControl={({ toggle }) => (
            <MenuItem
              divider={true}
              sx={{
                px: 1.5,
                py: 1,
                borderRadius: 0,
                borderBottomWidth: 0.5,
                borderBottomColor: "neutral.2",
              }}
              onClick={() => handleCreateNewWish(toggle)}
            >
              <MenuItemContent
                title="Wish"
                description="Add new item to your Wishlist"
                icon={<SparklesFilledIcon sx={{ width: 32, height: 32, color: "primary.4" }} />}
              />
            </MenuItem>
          )}
        />
        {/* Add Request Button */}
        {auth.user?.featureFlags.includes("request") && (
          <MenuItem sx={{ px: 1.5, py: 1, borderRadius: 0 }} onClick={handleCreateNewRequest}>
            <MenuItemContent
              title="Request"
              description="Request money from gifters"
              icon={
                <MoneyIcon sx={{ mx: 0.5, my: 0.5, width: 24, height: 24, color: "primary.4" }} />
              }
              label={{
                title: "New",
                icon: <SparklesFilledIcon sx={{ ml: 0.25, width: 16, color: "teal.6" }} />,
              }}
            />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
