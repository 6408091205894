import type { SxProps } from "@mui/material";
import { Box, IconButton, Tooltip } from "@mui/material";
import { IconLink } from "@tabler/icons-react";

import { Routes } from "~/constants/routes";
import { useCopyWithTimeout } from "~/hooks/use-copy-with-timeout";
import { useOrigin } from "~/hooks/use-origin";

export function CopyProfileLinkButton({ handle, sx }: { handle: string; sx?: SxProps }) {
  const [isCopied, copy] = useCopyWithTimeout();
  const origin = useOrigin();

  const wisherProfileUrl = `${origin}${Routes.public.wisher({
    username: handle,
  })}`;

  return (
    <Box sx={{ display: "inline-block", width: "24px", ...sx }}>
      <Tooltip title={isCopied ? "Copied!" : "Copy profile link"}>
        <IconButton
          aria-label="Copy profile link"
          size="small"
          sx={{
            color: "inherit",
            fontSize: "inherit",
            fontFamily: "inherit",
            fontWeight: "inherit",
            width: "24px",
            height: "24px",
            p: "0",
          }}
          onClick={async (event) => {
            event.stopPropagation();
            await copy(wisherProfileUrl);
          }}
        >
          <IconLink />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
