import { Box, Chip, Grid, IconButton, Typography } from "@mui/material";
import { IconAdjustmentsHorizontal } from "@tabler/icons-react";
import { useMemo, useState } from "react";

import { Button } from "~/components/button";
import { usePresentmentCurrency } from "~/modules/currency";
import { getTimeFromObjectID } from "~/utils/helpers";

import { useCategoriesParam } from "../hooks/use-categories-params";
import { useItemParams } from "../hooks/use-item-params";
import { useWishlistData } from "../hooks/use-wishlist-data";
import type { OrderItemType } from "../types";
import { AddToCartDialog } from "./add-to-cart-dialog";
import { CategoriesMenu } from "./categories";
import { ItemCard } from "./item-card";
import { SortItemsMenu } from "./sort-items-menu";

export function WishlistSectionPublic({ disabled }: { disabled?: boolean }) {
  const { wisher, wishlist } = useWishlistData();

  const gifterCurrency = usePresentmentCurrency(wisher.currency);

  const [selectedItem, setSelectedItem] = useItemParams(wishlist.items);

  const [selectedCategories, setSelectedCategories] = useCategoriesParam();

  const [selectedOrderType, setSelectedOrderType] = useState<OrderItemType>();

  const totalItems = wishlist.items?.length || 0;
  const wishlistItems = useMemo(() => {
    if (!wishlist.items) return [];
    const selectedCategoriesSet = new Set(selectedCategories);

    const items = wishlist.items.filter((item) => {
      if (selectedCategoriesSet.size === 0) return true;
      return item.categories?.some((category) => selectedCategoriesSet.has(category));
    });
    if (selectedOrderType) {
      items.sort((a, b) => {
        switch (selectedOrderType) {
          case "priceAsc":
            return a.price.amount - b.price.amount;
          case "priceDesc":
            return b.price.amount - a.price.amount;
          case "createdAtAsc":
            return getTimeFromObjectID(a.id).getTime() - getTimeFromObjectID(b.id).getTime();
          case "createdAtDesc":
            return getTimeFromObjectID(b.id).getTime() - getTimeFromObjectID(a.id).getTime();
          default:
            return 0;
        }
      });
    }

    return items;
  }, [wishlist.items, selectedCategories, selectedOrderType]);

  return (
    <Box sx={{ position: "relative", opacity: disabled ? 0.5 : 1 }}>
      {disabled && (
        <Box sx={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0, zIndex: 1 }} />
      )}
      <Box mt={2} />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <CategoriesMenu
          categories={wishlist.categories || []}
          selectedCategories={selectedCategories}
          onSelectCategories={setSelectedCategories}
          renderControl={({ open, toggle, menuId, controlId }) => {
            const isActive = open || selectedCategories?.length > 0;
            return (
              <Button
                id={controlId}
                variant={isActive ? "contained" : "outlined"}
                aria-controls={open ? menuId : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={toggle}
                sx={{
                  borderWidth: { xs: "1px", lg: "2px" },
                  ":hover": { borderWidth: { xs: "1px", lg: "2px" } },
                  maxHeight: { xs: "36px", lg: "40px" },
                  px: { xs: isActive ? "16px" : "15px", lg: isActive ? "24px" : "22px" },
                  fontSize: "14px",
                  lineHeight: "24px",
                }}
              >
                <span>Categories</span>
                {selectedCategories?.length > 0 && <span> ({selectedCategories.length})</span>}
              </Button>
            );
          }}
        />
        <Box mr={2} />
        <SortItemsMenu
          selected={selectedOrderType}
          onSelect={setSelectedOrderType}
          closeOnSelect
          renderControl={({ open, toggle, menuId, controlId }) => (
            <IconButton
              id={controlId}
              onClick={toggle}
              aria-controls={open ? menuId : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <IconAdjustmentsHorizontal />
            </IconButton>
          )}
        />
      </Box>
      {selectedCategories.length > 0 && (
        <>
          <Box mt={2} />
          <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "10px" }}>
            <Typography variant="b5" color="neutral.5">
              Found {wishlistItems.length} out of {totalItems} Wishes in:{" "}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "12px" }}>
              {selectedCategories.map((category) => (
                <Chip
                  key={category}
                  label={category}
                  variant="outlined"
                  onDelete={() => {
                    setSelectedCategories(selectedCategories.filter((c) => c !== category));
                  }}
                />
              ))}
            </Box>
          </Box>
        </>
      )}

      <Box mt={3} />

      <Grid container spacing={2}>
        {wishlistItems.map((item) => (
          <Grid item key={item.id} xs={6} sm={4} md={3} lg={3} xl={3}>
            <ItemCard
              wisherHandle={wisher.handle}
              item={item}
              onClick={() => setSelectedItem(item.id)}
            />
          </Grid>
        ))}
      </Grid>
      {selectedItem && (
        <AddToCartDialog
          gifterCurrency={gifterCurrency}
          item={selectedItem}
          onClosed={() => setSelectedItem(undefined)}
        />
      )}
    </Box>
  );
}
