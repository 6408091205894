import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";

type WishlistTab = "wishes" | "about";

type WishlistTabsProps = {
  render: (options: { tab: WishlistTab; setTab: (tab: WishlistTab) => void }) => React.ReactNode;
};

export function WishlistTabs(props: WishlistTabsProps) {
  const [tab, setTab] = useState<WishlistTab>("wishes");

  return (
    <>
      <Tabs
        centered
        variant="fullWidth"
        value={tab}
        onChange={(_, value) => setTab(value)}
        aria-label="Wishlist tabs"
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
        sx={{
          display: { xs: "flex", lg: "none" },
          marginRight: "-16px",
          marginLeft: "-16px",
          "& .MuiTabs-flexContainer": {
            justifyContent: "space-around",
            borderBottom: "1px solid #EDEFF5",
            marginBottom: "1px",
            paddingLeft: "16px",
            paddingRight: "16px",
          },
          "& .MuiTab-root": {
            fontSize: { xs: "16px", sm: "18px" },
            lineHeight: "24px",
            fontWeight: 400,
            "&.Mui-selected": {
              fontWeight: 600,
            },
          },
          "& .MuiTabs-indicator": {
            height: { xs: "3px", sm: "4px" },
            display: "flex",
            justifyContent: "center",
            backgroundColor: "transparent",
          },
          "& .MuiTabs-indicatorSpan": {
            maxWidth: "72px",
            width: "100%",
            borderRadius: "8px",
            backgroundColor: "primary.main",
          },
        }}
      >
        <Tab label="Wishes" value="wishes" />
        <Tab label="About" value="about" />
      </Tabs>
      {props.render({ tab, setTab })}
    </>
  );
}
