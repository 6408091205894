import { Avatar, Box, Card, Typography } from "@mui/material";

import { Button } from "~/components/button";
import { ExclamationCircleFilledIcon } from "~/components/icons";
import { PAYMENT_TRANSITION_DETAIL_URL } from "~/constants/platform";

export function DeactivatedCard({
  isNewWisher,
  isGifterPov,
}: {
  isNewWisher?: boolean;
  isGifterPov?: boolean;
}) {
  if (isGifterPov) return <DeactivatedCardGifterPov />;
  if (isNewWisher) return <DeactivatedCardNewWisher />;
  return <DeactivatedCardWisher />;
}

function DeactivatedCardWisher() {
  return (
    <Card>
      <Box p={3}>
        <Avatar sx={{ bgcolor: "yellow.0", color: "yellow.4" }}>
          <ExclamationCircleFilledIcon />
        </Avatar>
        <Box mt={2} />
        <Typography component="p" variant="bh7" color="neutral.6">
          Your wishlist is temporarily deactivated due to payment processor updates.
        </Typography>
        <Box mt={1} />
        <Typography component="p" variant="b5" color="neutral.7">
          Due to a sudden change in policy, our current processor no longer supports payments to
          your wishlist. We are switching to a new payment system.
        </Typography>
        <Box mt={1.5} />
        <Typography component="p" variant="b5" color="neutral.7">
          Your wishlist is temporarily deactivated during this time. We will notify you by email and
          in-app notification to let you know when you will be able to reactivate your wishlist and
          receive payments.
        </Typography>
        <Box mt={2} />
        <Button
          variant="text"
          component="a"
          href={PAYMENT_TRANSITION_DETAIL_URL}
          target="_blank"
          rel="noreferrer noopener"
          sx={{ px: 0, mx: -0.5 }}
        >
          Read article about transition
        </Button>
      </Box>
    </Card>
  );
}

function DeactivatedCardNewWisher() {
  return (
    <Card>
      <Box p={3}>
        <Avatar sx={{ bgcolor: "yellow.0", color: "yellow.4" }}>
          <ExclamationCircleFilledIcon />
        </Avatar>
        <Box mt={2} />
        <Typography component="p" variant="bh7" color="neutral.6">
          You are unable to activate your wishlist due to payment processor updates.
        </Typography>
        <Box mt={1} />
        <Typography component="p" variant="b5" color="neutral.7">
          Due to a sudden change in policy, our current processor no longer supports payouts. We are
          switching to a new payout solution.
          <br />
          <br />
          You are unable to activate your wishlist during this time. We will notify you by email and
          in-app notification, when you should be ready to activate your wishlist and receive
          payments.
          <br />
          <br />
          In the meantime, you are still able to customize your wishlist by adding new wishes,
          avatar, banner and social links.
        </Typography>
        <Box mt={2} />
        <Button
          variant="text"
          component="a"
          href={PAYMENT_TRANSITION_DETAIL_URL}
          target="_blank"
          rel="noreferrer noopener"
          sx={{ px: 0, mx: -0.5 }}
        >
          Read article about transition
        </Button>
      </Box>
    </Card>
  );
}

function DeactivatedCardGifterPov() {
  return (
    <Card>
      <Box p={3}>
        <Avatar sx={{ bgcolor: "yellow.0", color: "yellow.4" }}>
          <ExclamationCircleFilledIcon />
        </Avatar>
        <Box mt={2} />
        <Typography component="p" variant="bh7" color="neutral.6">
          This wishlist is temporarily deactivated due to payment processor updates.
        </Typography>
        <Box mt={1} />
        <Typography component="p" variant="b5" color="neutral.7">
          Due to a sudden change in policy, our current processor no longer supports payouts. We are
          switching to a new international payout solution.
          <br />
          <br />
          This wishlist is temporarily deactivated during this time. This wisher won't be able to
          receive gifts.
        </Typography>
      </Box>
    </Card>
  );
}
