import { Paper } from "@mui/material";
import type { ReactNode } from "react";

export function ProfileCard(props: { children: ReactNode }) {
  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: { xs: "flex-start", lg: "center" },
        gap: { xs: "12px", lg: "32px" },
        textAlign: "center",
        borderRadius: "12px",
        px: { xs: 0, lg: "40px" },
        pb: { xs: "12px", sm: "24px", lg: "40px" },
        pt: { xs: 0, lg: "24px" },
        backgroundColor: { xs: "transparent", lg: "white" },
        boxShadow: {
          xs: "none",
          lg: "0px 2px 4px 0px rgba(38, 103, 255, 0.10), 0px 0px 1px 0px rgba(38, 103, 255, 0.05);",
        },
      }}
    >
      {props.children}
    </Paper>
  );
}
