import { Card, CardContent, Typography } from "@mui/material";

import type { Wisher, Wishlist } from "~/types";

import { ShareTwitterSection } from "./share-twitter-section";
import { SocialLinksSectionEditable } from "./social-links-section-editable";

export function AboutCardEditable({ wisher, wishlist }: { wisher: Wisher; wishlist: Wishlist }) {
  return (
    <Card>
      <CardContent
        sx={{ display: "flex", flexDirection: "column", gap: 4, textAlign: "center", p: 3 }}
      >
        <Typography variant="b5" component="p" color="neutral.7">
          {wisher.bio}
        </Typography>
        <div>
          <SocialLinksSectionEditable wisher={wisher} />
        </div>
        <ShareTwitterSection handle={wisher.handle} enabled={wishlist.surpriseGift.enabled} />
      </CardContent>
    </Card>
  );
}
