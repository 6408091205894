import { LoadingButton } from "@mui/lab";
import { Box, ButtonBase, Typography } from "@mui/material";
import { IconPlus } from "@tabler/icons-react";
import type { FormEvent } from "react";
import { useState } from "react";

import { CropImage } from "~/components/crop-image";
import { Dialog } from "~/components/dialog";
import { HiddenFileInput } from "~/components/form/hidden-file-input";
import { Routes } from "~/constants/routes";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";

import { COVER_DIMENSIONS } from "../../constants";
import { Cover } from "./cover";

type AvatarEditableProps = {
  wishlistId: string;
  src?: string;
  alt: string;
};

export function CoverEditable(props: AvatarEditableProps) {
  const { wishlistId, src, alt } = props;

  const [image, setImage] = useState<string>();
  const [croppedImage, setCroppedImage] = useState<Blob>();

  function handleClose() {
    setImage(undefined);
    setCroppedImage(undefined);
  }

  const [submit, submitState] = useWtFetcher({
    onSuccess: () => {
      setImage(undefined);
      setCroppedImage(undefined);
    },
    onError: (response) => alert(response.error),
  });

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!croppedImage) return;
    const formData = new FormData();
    formData.append("image", croppedImage);
    formData.append("wishlistId", wishlistId);
    submit.submit(formData, {
      action: Routes.actions.wisher.profile.updateCover(),
      method: "POST",
      encType: "multipart/form-data",
    });
  }

  return (
    <Box sx={{ position: "relative" }}>
      <Cover src={src} alt={alt} />
      <Box sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
        {!src && (
          <HiddenFileInput
            onChange={(imageUri) => setImage(imageUri)}
            renderControl={({ onSelect }) => <UploadCoverPlaceholderButton onClick={onSelect} />}
          />
        )}

        <Dialog.Root maxWidth="xs" variant="normal" open={!!image} fullWidth onClose={handleClose}>
          <Dialog.Title>Update Cover</Dialog.Title>
          <Box component="form" onSubmit={handleSubmit}>
            <Dialog.Content>
              <CropImage
                cropShape="rect"
                image={image}
                onCrop={setCroppedImage}
                aspectRatio={COVER_DIMENSIONS.width / COVER_DIMENSIONS.height}
                dimensions={COVER_DIMENSIONS}
              />
              <Box mt={4} />
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                loading={submitState.isFetching}
              >
                <span>Crop And Save</span>
              </LoadingButton>
            </Dialog.Content>
          </Box>
        </Dialog.Root>
      </Box>
    </Box>
  );
}

function UploadCoverPlaceholderButton({ onClick }: { onClick: () => void }) {
  return (
    <ButtonBase
      disableRipple
      onClick={onClick}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: { xs: "row", lg: "column" },
        height: "100%",
        width: "100%",
        gap: 2,
        pt: 6,
        pb: 10,
        color: "#fff",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: { xs: 32, lg: 40 },
          height: { xs: 32, lg: 40 },
          background: "#fff",
          borderRadius: "50%",
          color: "gray.4",
        }}
      >
        <IconPlus size={32} />
      </Box>
      <Typography variant="h9" sx={{ display: { xs: "none", lg: "block" } }}>
        Add a Cover Image (Optional)
      </Typography>
      <Typography variant="h6" sx={{ display: { xs: "block", lg: "none" } }}>
        Add a Cover Image (Optional)
      </Typography>
      <Typography variant="h6" sx={{ display: { xs: "none", lg: "block" } }}>
        Optimal Dimensions: {COVER_DIMENSIONS.width}${COVER_DIMENSIONS.height} px
      </Typography>
    </ButtonBase>
  );
}
