import { Box, IconButton, Tooltip } from "@mui/material";

import type { SocialLink } from "~/types";

import { SOCIAL_PLATFORMS } from "../../constants";

export function SocialLinks({
  handle,
  socialLinks,
}: {
  handle: string;
  socialLinks: SocialLink[];
}) {
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, justifyContent: "center" }}>
      {socialLinks
        .filter((link) => !link.disabled)
        .map((socialLink) => {
          const Icon = SOCIAL_PLATFORMS[socialLink.platform]?.Icon || SOCIAL_PLATFORMS.other.Icon;
          return (
            <Tooltip key={socialLink.url} title={socialLink.url}>
              <IconButton
                component="a"
                target="_blank"
                rel="noopener noreferrer"
                href={socialLink.url}
                aria-label={`Visit @${handle}'s ${socialLink.platform} link at ${socialLink.url}`}
                sx={{
                  backgroundColor: "neutral.2",
                  color: "neutral.7",
                }}
              >
                <Icon sx={{ fontSize: 20 }} />
              </IconButton>
            </Tooltip>
          );
        })}
    </Box>
  );
}
