import { Box, Typography } from "@mui/material";
import { IconInfoCircleFilled } from "@tabler/icons-react";

import type { Wisher } from "~/types";

type WishlistUnactivatedNoticePublicProps = {
  wisher: Wisher;
};

export function WishlistUnactivatedNoticePublic({ wisher }: WishlistUnactivatedNoticePublicProps) {
  if (wisher.activated) return <></>;
  return (
    <Box sx={{ display: "flex", justifyContent: "center", px: 2, py: 5, textAlign: "center" }}>
      <Box sx={{ maxWidth: 600, mx: "auto" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Box sx={{ color: "blue.4", height: "24px" }}>
            <IconInfoCircleFilled size={24} />
          </Box>
          <Typography variant="h7">
            <span>@{wisher.handle}</span> <span> has not activated their wishlist yet</span>
          </Typography>
        </Box>
        <Box mt={3} />
        <Typography variant="b5">
          Until they activate their wishlist, this user won't be able to receive gifts
        </Typography>
      </Box>
    </Box>
  );
}
