import type { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export function useDragStyles(sortableProps: ReturnType<typeof useSortable>) {
  const { transition, transform, isDragging } = sortableProps || {};

  const css = sortableProps
    ? // actual item
      {
        transform: CSS.Transform.toString(transform),
        transition: transition,
        zIndex: isDragging ? 1 : "auto",
        background: isDragging ? "rgb(0 0 0 / 10%)" : "white",
      }
    : // overlay
      {
        transform: "rotate(2deg)",
        boxShadow: "0px 4px 8px rgb(0 0 0 / 10%)",
      };

  const contentCss = sortableProps ? { opacity: sortableProps.isDragging ? 0 : 1 } : undefined;

  return { css, contentCss };
}
