import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";

import { CropImage } from "~/components/crop-image";

import { ITEM_IMAGE_DIMENSIONS } from "../constants";

type ItemImageSelectProps = {
  images: string[];
  noCrop?: boolean;
  onSelected: (image: {
    url: string;
    crop?: { x: number; y: number; width: number; height: number };
  }) => void;
};

export function ItemImageSelect(props: ItemImageSelectProps) {
  const [displayImage, setDisplayImage] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    if (props.noCrop) props.onSelected({ url: props.images[displayImage] });
  }, [displayImage, props]);

  function move(step: number) {
    let newDisplayImage = displayImage + step;
    if (newDisplayImage > props.images.length - 1 && step > 0) {
      newDisplayImage = 0;
    }
    if (newDisplayImage < 0 && step < 0) {
      newDisplayImage = props.images.length - 1;
    }
    setDisplayImage(newDisplayImage);
  }
  function handleSetCrop(_: any, croppedInfo: any) {
    props.onSelected({
      url: props.images[displayImage],
      crop: croppedInfo.croppedAreaPixels,
    });
  }

  const label = props.noCrop ? "Choose Image Or Upload" : "Choose & Position Image";

  return (
    <Box sx={{ display: "inherit", gap: "inherit" }}>
      <Typography component="label">{label}</Typography>
      <Box mt={1} />
      <Box sx={{ position: "relative", width: "80%", margin: "auto" }}>
        {props.noCrop ? (
          <div>
            <img
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
              }}
              src={props.images[displayImage]}
              alt="manual gift default"
            />
          </div>
        ) : (
          <CropImage
            aspectRatio={1}
            dimensions={ITEM_IMAGE_DIMENSIONS}
            cropShape="rect"
            onCrop={handleSetCrop}
            image={props.images[displayImage]}
          ></CropImage>
        )}
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            background: theme.palette.primary.main + "80",
          }}
        >
          <IconButton
            onClick={() => {
              move(-1);
            }}
            size="large"
          >
            <NavigateBeforeIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              move(1);
            }}
            size="large"
          >
            <NavigateNextIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}
