import { ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import { IconSquare, IconSquareCheckFilled } from "@tabler/icons-react";
import type { MouseEvent, ReactNode } from "react";
import { useId, useState } from "react";

type CategoriesMenuProps = {
  categories: string[];
  selectedCategories: string[];
  onSelectCategories: (categories: string[]) => void;
  renderControl: (props: {
    open: boolean;
    controlId: string;
    menuId: string;
    toggle: (event: MouseEvent<HTMLButtonElement>) => void;
  }) => ReactNode;
};

export function CategoriesMenu(props: CategoriesMenuProps) {
  const { categories, selectedCategories, onSelectCategories } = props;

  const selected = new Set(selectedCategories);

  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement>();
  const menuId = useId();
  const controlId = useId();

  function handleOpen(event: MouseEvent<HTMLButtonElement>) {
    setAnchorElement(event.currentTarget);
  }

  function handleClose() {
    setAnchorElement(undefined);
  }

  function selectItem(category: string) {
    if (selected.has(category)) {
      selected.delete(category);
    } else {
      selected.add(category);
    }
    onSelectCategories?.([...selected]);
  }

  function handleSelectAll() {
    onSelectCategories?.([]);
  }

  const open = Boolean(anchorElement);

  return (
    <>
      {props.renderControl({ open, toggle: handleOpen, controlId, menuId })}
      <Menu
        id={menuId}
        open={open}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        MenuListProps={{
          "aria-labelledby": controlId,
          sx: { minWidth: "260px" },
        }}
        sx={{ mt: 1 }}
      >
        <MenuItem selected={selected.size === 0} onClick={handleSelectAll}>
          <ListItemIcon>
            {selected.size === 0 ? <IconSquareCheckFilled /> : <IconSquare />}
          </ListItemIcon>
          <ListItemText>All</ListItemText>
        </MenuItem>
        {categories.map((category) => (
          <MenuItem
            key={category}
            selected={selected.has(category)}
            onClick={() => selectItem(category)}
          >
            <ListItemIcon sx={{ ml: 2 }}>
              {selected.has(category) ? <IconSquareCheckFilled /> : <IconSquare />}
            </ListItemIcon>
            <ListItemText title={category}>
              <Typography variant="inherit" noWrap>
                {category}
              </Typography>
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
