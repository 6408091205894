import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useDndContext,
  useSensor,
  useSensors } from
"@dnd-kit/core";
import {
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable } from
"@dnd-kit/sortable";
import { Box, Card, Grid } from "@mui/material";
import { useId } from "react";

import type { Wisher, WishlistItem } from "~/types";

import { useDragStyles } from "../hooks/use-drag-styles";
import { ItemCard } from "./item-card";

// A simple type of { DragEndEvent } from "@dnd-kit/core";
export type DragEndEvent = {
  active: {id: string | number;};
  over: {id: string | number;} | null;
};

type WishlistItemsDraggableProps = {
  wisher: Wisher;
  items: WishlistItem[];
  disabled?: boolean;
  onDragEnd?: (event: DragEndEvent) => void;
  onClickItem?: (item: WishlistItem) => void;
};

export function WishlistItemsDraggable({
  wisher,
  items,
  disabled,
  onDragEnd,
  onClickItem
}: WishlistItemsDraggableProps) {
  const id = useId();
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  function showMoveToTop(index: number) {
    return index + 1 > items.length - 2 && items.length > 7;
  }

  function showMoveToBottom(index: number) {
    return index + 1 < 3 && items.length > 7;
  }

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd} id={id}>
      <Grid container spacing={2}>
        <SortableContext items={items} strategy={rectSortingStrategy}>
          {items.map((item, index) =>
          <Grid item key={item.id} xs={6} sm={4} md={3} lg={3} xl={3}>
              {disabled ?
            <ItemCard
              wisherHandle={wisher.handle}
              item={item}
              enableTwitterShare
              onClick={() => onClickItem?.(item)} /> :


            <ItemDraggable
              item={item}
              wisher={wisher}
              showMoveToTop={showMoveToTop(index)}
              showMoveToBottom={showMoveToBottom(index)}
              onClickMoveToTop={() => {
                onDragEnd?.({
                  active: { id: item.id },
                  over: { id: items[0].id }
                });
              }}
              onClickMoveToBottom={() => {
                onDragEnd?.({
                  active: { id: item.id },
                  over: { id: items[items.length - 1].id }
                });
              }}
              onClick={() => onClickItem?.(item)} />}


            </Grid>
          )}
        </SortableContext>
        <ItemsDraggableOverlay items={items} />
      </Grid>
    </DndContext>);

}

type ItemDraggableProps = {
  item: WishlistItem;
  wisher: Wisher;
  showMoveToTop?: boolean;
  showMoveToBottom?: boolean;
  onClickMoveToTop?: () => void;
  onClickMoveToBottom?: () => void;
  onClick?: () => void;
};

function ItemDraggable({ item, wisher, ...props }: ItemDraggableProps) {
  const sortableProps = useSortable({ id: item.id });
  const { setNodeRef, attributes, listeners } = sortableProps || {};
  const { css, contentCss } = useDragStyles(sortableProps);
  return (
    <Card
      ref={setNodeRef}
      {...attributes}
      sx={{
        height: "100%",
        ...css
      }}>

      <Box sx={{ height: "100%", width: "100%", ...contentCss }}>
        <ItemCard
          wisherHandle={wisher.handle}
          item={item}
          enableTwitterShare
          draggable
          dragHandleProps={listeners}
          {...props} />

      </Box>
    </Card>);

}

function ItemsDraggableOverlay({ items }: {items: WishlistItem[];}) {
  const { active } = useDndContext();
  const activeItem = items.find((item) => item.id === active?.id);

  return (
    <DragOverlay>
      {activeItem && <ItemCard wisherHandle="" item={activeItem} enableTwitterShare draggable />}
    </DragOverlay>);

}