import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { IconX } from "@tabler/icons-react";
import { forwardRef, useState } from "react";

import { TextField } from "~/components/form/text-field";
import { TAG_VALIDATION_REGEX } from "~/constants/regex";

type ItemTagsInputProps = {
  value?: string[];
  onChange?: (value: string[]) => void;
  errorMessage?: string;
};

const MAX_ITEM_TAGS = 5;

export const ItemTagsInput = forwardRef<HTMLInputElement, ItemTagsInputProps>(function (
  { value = [], onChange, errorMessage },
  ref,
) {
  const [inputTagText, setInputTagText] = useState("");
  const [focused, setFocused] = useState(false);

  const duplicated = value.includes(inputTagText.trim());

  const disabled = value.length >= MAX_ITEM_TAGS;

  function handleDeleteTag(index: number) {
    onChange?.(value?.filter((_tag, index_) => index_ !== index));
  }

  function handleAddTag() {
    const inputValue = inputTagText.trim();
    const newTags = new Set([...value, inputValue]);
    onChange?.([...newTags]);
    setInputTagText("");
  }

  const placeholder =
    value.length === 0
      ? `Add up to ${MAX_ITEM_TAGS} keywords`
      : `${disabled ? 0 : MAX_ITEM_TAGS - value.length} keywords remaining`;

  return (
    <TextField.Root fullWidth>
      <TextField.Label>
        <Typography component="span" variant="h5">
          Tag this Wish (optional)
        </Typography>
      </TextField.Label>
      <Box mt={1} />
      <TextField.Helper sx={{ m: 0 }}>
        <Typography color="neutral.6">
          Help others discover your wish by adding tags, avoid using explicit terms or inappropriate
          language.
        </Typography>
      </TextField.Helper>
      <Box mt={1} />
      <ClickAwayListener onClickAway={() => setFocused(false)}>
        <Stack spacing={1} direction="row" sx={{ width: "100%", alignItems: "flex-start" }}>
          <Box sx={{ flex: 1, transition: "all ease-in-out 0.2s" }}>
            <TextField.Input
              fullWidth
              disabled={disabled}
              value={inputTagText}
              placeholder={placeholder}
              autoComplete="off"
              onChange={(event) => {
                const inputValue = event.target.value || "";
                if (!inputValue) return setInputTagText("");
                if (inputValue.length <= 40 && TAG_VALIDATION_REGEX.test(inputValue)) {
                  setInputTagText(inputValue);
                }
              }}
              ref={ref}
              onFocus={() => setFocused(true)}
            />
            {(errorMessage || duplicated) && (
              <TextField.Helper error>
                {errorMessage || "This keyword already exists"}
              </TextField.Helper>
            )}
          </Box>
          <Button
            type="button"
            variant="text"
            disabled={disabled || inputTagText === "" || duplicated}
            onClick={handleAddTag}
            sx={{ display: focused ? "block" : "none", px: 1 }}
          >
            Add
          </Button>
        </Stack>
      </ClickAwayListener>
      {value.length > 0 && (
        <Stack direction="row" sx={{ flexWrap: "wrap", gap: 1, my: 2 }}>
          {value.map((tag: string, index: number) => (
            <Chip
              key={tag}
              variant="outlined"
              label={tag}
              deleteIcon={<IconX size={14} />}
              onDelete={() => handleDeleteTag(index)}
            />
          ))}
        </Stack>
      )}
    </TextField.Root>
  );
});

ItemTagsInput.displayName = "ItemTagsInput";
