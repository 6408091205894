import { Box, Typography } from "@mui/material";

import { toSmallestUnit, usePresentmentCurrency } from "~/modules/currency";

import {
  AboutCard,
  Avatar,
  CopyProfileLinkButton,
  Cover,
  ProfileCard,
  SocialLinksSection,
} from "../components/profile";
import { SendATipButton } from "../components/send-a-tip-button";
import { SendATipDialog } from "../components/send-a-tip-dialog";
import { WishlistSectionPublic } from "../components/wishlist.public";
import { WishlistFrozenNoticePublic } from "../components/wishlist-frozen-notice.public";
import { WishlistPageLayout } from "../components/wishlist-page-layout";
import { WishlistTabs } from "../components/wishlist-tabs";
import { WishlistUnactivatedNoticePublic } from "../components/wishlist-unactivated-notice.public";
import { useSendSurpriseParams } from "../hooks/use-send-surprise-params";
import { useWishlistData } from "../hooks/use-wishlist-data";

export function WishlistPagePublic() {
  const { wisher, wishlist, canSendTip, showWishlist, gifterToWisherRate } = useWishlistData();
  const gifterCurrency = usePresentmentCurrency(wisher.currency);
  const [surpriseParams, setSurpriseParams] = useSendSurpriseParams();
  const parsedAmount = Number.parseInt(
    toSmallestUnit(String(surpriseParams.amount), wisher.currency),
  );
  const defaultAmount = Number.isNaN(parsedAmount) ? undefined : parsedAmount;

  const isDeactivated = !wisher.activated;

  return (
    <WishlistPageLayout
      header={wisher.coverUrl && <Cover src={wisher.coverUrl} alt={`${wishlist.name} cover`} />}
      left={
        <ProfileCard>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              gap: 2,
              width: "100%",
            }}
          >
            <Avatar src={wisher.avatarUrl} alt={`${wisher.handle} avatar`} />
            <Box sx={{ display: { xs: "block", lg: "none" }, ml: "auto" }}>
              {canSendTip && (
                <SendATipButton
                  onClick={() => setSurpriseParams({ open: true })}
                  disabled={isDeactivated}
                />
              )}
            </Box>
          </Box>
          <Box className="notranslate" sx={{ textAlign: { xs: "left", lg: "center" } }}>
            <Typography component="h1" variant="h8">
              {wishlist?.name}
            </Typography>
            <Typography variant="b5" color="neutral.5" sx={{ position: "relative", mt: "2px" }}>
              <span>@{wisher.handle}</span>
              <CopyProfileLinkButton handle={wisher.handle} sx={{ ml: 1 }} />
            </Typography>
          </Box>
          {wisher.bio && (
            <Typography variant="b5" sx={{ textAlign: { xs: "left", lg: "center" } }}>
              {wisher.bio}
            </Typography>
          )}
          <Box
            sx={{
              display: { xs: "none", lg: "flex", flexDirection: "column" },
              gap: "32px",
            }}
          >
            {canSendTip && (
              <SendATipButton
                onClick={() => setSurpriseParams({ open: true })}
                disabled={isDeactivated}
              />
            )}
            <SocialLinksSection handle={wisher.handle} socialLinks={wisher.socialLinks} />
          </Box>
        </ProfileCard>
      }
    >
      <WishlistTabs
        render={({ tab }) => (
          <>
            <Box mt={2} />
            <Box sx={{ display: { xs: tab === "wishes" ? "block" : "none", lg: "block" } }}>
              <WishlistFrozenNoticePublic wisher={wisher} />
              <WishlistUnactivatedNoticePublic wisher={wisher} />
              {showWishlist && <WishlistSectionPublic disabled={isDeactivated} />}
            </Box>
            <Box sx={{ display: { xs: tab === "about" ? "block" : "none", lg: "none" } }}>
              <AboutCard wisher={wisher} />
            </Box>
          </>
        )}
      />

      {canSendTip && surpriseParams.open && (
        <SendATipDialog
          wisherId={wisher.id}
          gifterCurrency={gifterCurrency}
          wisherCurrency={wisher.currency}
          minAmount={wishlist?.surpriseGift.enabled ? wishlist?.surpriseGift.minAmount : undefined}
          gifterToWisherRate={gifterToWisherRate}
          defaultAmount={defaultAmount}
          defaultNote={surpriseParams.note}
          onClosed={() => setSurpriseParams({ open: false })}
        />
      )}
    </WishlistPageLayout>
  );
}
