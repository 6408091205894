import { Button, Tooltip } from "@mui/material";
import { IconCopy } from "@tabler/icons-react";

import { Routes } from "~/constants/routes";
import { useCopyWithTimeout } from "~/hooks/use-copy-with-timeout";
import { useOrigin } from "~/hooks/use-origin";

type CopyCategoriesLinkButtonProps = {
  handle: string;
  categories: string[];
};

export function CopyCategoriesLinkButton({ handle, categories }: CopyCategoriesLinkButtonProps) {
  const origin = useOrigin();
  const params = new URLSearchParams({ categories: categories.join(",") });
  const url = `${origin}${Routes.public.wisher({ username: handle })}?${params.toString()}`;
  const [isCopied, copy] = useCopyWithTimeout();

  return (
    <Tooltip title={isCopied ? "Link copied!" : "Copy link to share"}>
      <Button
        size="small"
        variant="text"
        color="inherit"
        onClick={async (event) => {
          event.stopPropagation();
          await copy(url);
        }}
        endIcon={<IconCopy size={20} />}
      >
        Copy link to these categories
      </Button>
    </Tooltip>
  );
}
