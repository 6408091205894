import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import type { z } from "zod";

import { TextField } from "~/components/form/text-field";
import { Routes } from "~/constants/routes";
import { useAuth } from "~/hooks/use-auth";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";
import { toSmallestUnit, usePresentmentCurrency } from "~/modules/currency";
import type { Wishlist } from "~/types";

import { usePollingItemWorker } from "../../hooks/use-polling-item-worker";
import { AddNewItemSchema } from "../../schemas";
import { ItemCategoriesInput } from "../item-categories-input";
import { ItemImageSelect } from "../item-image-select";
import { ItemTagsInput } from "../item-tags-input";
import { ItemTypeInput } from "../item-type-input";

type AddNewItemWithURLFormProps = {
  wishlist: Wishlist;
  onSuccess?: () => void;
};

export function AddNewItemWithURLForm({ wishlist, onSuccess }: AddNewItemWithURLFormProps) {
  const currency = usePresentmentCurrency("USD");
  const { user } = useAuth();

  const [retrieved, setRetrieved] = useState(false);
  const [images, setImages] = useState([]);

  const form = useForm<z.infer<typeof AddNewItemSchema>>({
    resolver: zodResolver(AddNewItemSchema),
    defaultValues: {
      wishlist: wishlist.id,
      type: "item",
      url: "",
      currency,
      categories: [],
      tags: [],
    },
  });

  const poll = usePollingItemWorker({
    onSuccess: () => {
      onSuccess?.();
      form.reset();
    },
    onError: (response) => {
      alert(response.error);
    },
  });

  const [submit, submitState] = useWtFetcher({
    onSuccess: (response: any) => {
      if (response.data.id) poll.start({ id: response.data.id });
    },
    onError: (response) => alert(response.error),
  });

  const [submitScrape, submitScrapeState] = useWtFetcher({
    onSuccess: async (response: any) => {
      if (response.data) {
        const { title, imageSrcs, price, currency: itemCurrency } = response.data;
        form.setValue("itemName", title);
        if (price) {
          const parsedPrice = Number.parseInt(toSmallestUnit(price, itemCurrency || currency));
          form.setValue("price", parsedPrice);
        }
        if (itemCurrency) form.setValue("currency", itemCurrency);
        if (imageSrcs.length > 0) {
          setImages(imageSrcs);
        }
        setRetrieved(true);
      } else {
        alert("Could not get item information.");
      }
    },
    onError: (response) => alert(response.error),
  });

  function handleSubmit(data: z.infer<typeof AddNewItemSchema>) {
    submit.submit(
      {
        body: JSON.stringify(data),
      },
      {
        method: "POST",
        action: Routes.actions.wisher.wishlist.item.addWorker(),
      },
    );
  }

  const scrapeUrl = form.watch("url");
  function handleSubmitScrape() {
    submitScrape.submit(
      { url: String(scrapeUrl) },
      { method: "POST", action: Routes.actions.wisher.wishlist.productInfo() },
    );
  }

  const loading = submitState.isFetching || poll.state === "waiting";

  return (
    <Box
      component="form"
      method="POST"
      onSubmit={form.handleSubmit(handleSubmit)}
      autoComplete="off"
    >
      <Controller
        name="url"
        control={form.control}
        render={({ field, fieldState }) => (
          <TextField.Root fullWidth>
            <TextField.Label>Prefill with URL</TextField.Label>
            <TextField.Input
              placeholder="Enter item URL"
              autoFocus
              autoComplete="off"
              {...field}
              onChange={async (event) => {
                field.onChange(event);
                await form.trigger("url");
              }}
            />
            {fieldState.error && (
              <TextField.Helper error>{fieldState.error.message}</TextField.Helper>
            )}
          </TextField.Root>
        )}
      />
      <Box mt={2} />
      <LoadingButton
        fullWidth
        variant="contained"
        color="primary"
        loading={submitScrapeState.isFetching}
        onClick={handleSubmitScrape}
        type="button"
      >
        <span>Prefill Wish Information</span>
      </LoadingButton>
      <Box mt={4} />
      <Typography variant="h6">Wish Information</Typography>
      <Box mt={2} />
      <Box sx={retrieved ? {} : { opacity: 0.3, pointerEvents: "none" }}>
        <Controller
          name="imageCrop"
          control={form.control}
          render={({ field }) => (
            <ItemImageSelect onSelected={(image) => field.onChange(image)} images={images} />
          )}
        />
        <Box mt={2} />
        <Controller
          name="itemName"
          control={form.control}
          defaultValue=""
          render={({ field, fieldState }) => (
            <TextField.Root fullWidth>
              <TextField.Label>Wish Name</TextField.Label>
              <TextField.Input placeholder="Give the wish a name" autoComplete="off" {...field} />
              {fieldState.error && (
                <TextField.Helper error>{fieldState.error.message}</TextField.Helper>
              )}
            </TextField.Root>
          )}
        />
        <Box mt={2} />
        <Controller
          name="price"
          control={form.control}
          defaultValue={0}
          render={({ field, fieldState }) => (
            <TextField.Root fullWidth>
              <TextField.Label>Price</TextField.Label>
              <TextField.InputMoney {...field} currency={currency} autoComplete="off" />
              {fieldState.error && (
                <TextField.Helper error>{fieldState.error.message}</TextField.Helper>
              )}
              <TextField.Helper>
                Don't forget to add to the total to cover shipping and tax.
              </TextField.Helper>
            </TextField.Root>
          )}
        />
        <Box mt={2} />
        <ItemTypeInput control={form.control} />
        <Box mt={3} />
        <Typography variant="h7" color="neutral.7">
          Publish
        </Typography>
        <Box mt={2} />
        <Controller
          name="categories"
          control={form.control}
          render={({ field, fieldState }) => (
            <ItemCategoriesInput
              {...field}
              errorMessage={fieldState.error?.message}
              options={wishlist.categories}
            />
          )}
        />
        {user?.featureFlags?.includes("search") && (
          <>
            <Box mt={3}>
              <Controller
                name="tags"
                control={form.control}
                render={({ field, fieldState }) => (
                  <ItemTagsInput {...field} errorMessage={fieldState.error?.message} />
                )}
              />
            </Box>
          </>
        )}
        <Box mt={4} />
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          loading={loading}
        >
          <span>Add Wish</span>
        </LoadingButton>
      </Box>
    </Box>
  );
}
