import type { SxProps } from "@mui/material";

import { Button } from "~/components/button";
import { BacknoteSparkleIcon } from "~/components/icons";
import { primaryGradientBackgroundStyle } from "~/constants/styles";

type SendATipButtonProps = {
  onClick?: () => void;
  sx?: SxProps;
  disabled?: boolean;
};

export function SendATipButton({ onClick, sx, disabled }: SendATipButtonProps) {
  return (
    <Button
      onClick={onClick}
      disableElevation
      variant="gradient"
      sx={{
        ...primaryGradientBackgroundStyle,
        width: { xs: "inherit", lg: "100%" },
        ...sx,
      }}
      endIcon={<BacknoteSparkleIcon />}
      disabled={disabled}
    >
      Send Surprise
    </Button>
  );
}
