import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { LoadingButton } from "@mui/lab";
import { Box, IconButton } from "@mui/material";
import type { FormEvent } from "react";
import { useState } from "react";

import { CropImage } from "~/components/crop-image";
import { Dialog } from "~/components/dialog";
import { HiddenFileInput } from "~/components/form/hidden-file-input";
import { Routes } from "~/constants/routes";
import { primaryGradientBackgroundStyle } from "~/constants/styles";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";

import { Avatar } from "./avatar";

type AvatarEditableProps = {
  wisherId: string;
  src: string;
  alt: string;
};

export function AvatarEditable(props: AvatarEditableProps) {
  const { wisherId, src, alt } = props;

  const [image, setImage] = useState<string>();
  const [croppedImage, setCroppedImage] = useState<Blob>();

  function handleClose() {
    setImage(undefined);
    setCroppedImage(undefined);
  }

  const [submit, submitState] = useWtFetcher({
    onSuccess: () => {
      setImage(undefined);
      setCroppedImage(undefined);
    },
    onError: (response) => alert(response.error),
  });

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!croppedImage) return;
    const formData = new FormData();
    formData.append("image", croppedImage);
    formData.append("wisherId", wisherId);
    submit.submit(formData, {
      action: Routes.actions.wisher.profile.updateAvatar(),
      method: "POST",
      encType: "multipart/form-data",
    });
  }

  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
      }}
    >
      <Avatar src={src} alt={alt} />
      <Box
        sx={{
          position: "absolute",
          bottom: { xs: 4, md: 8, lg: 12 },
          right: { xs: 4, md: 8, lg: 12 },
        }}
      >
        <HiddenFileInput
          onChange={(imageUri) => setImage(imageUri)}
          renderControl={({ onSelect }) => (
            <IconButton
              onClick={onSelect}
              aria-label="Edit avatar"
              size="small"
              sx={{
                ...primaryGradientBackgroundStyle,
                color: "black",
              }}
            >
              <PhotoCameraIcon />
            </IconButton>
          )}
        />
        <Dialog.Root maxWidth="xs" variant="normal" open={!!image} fullWidth onClose={handleClose}>
          <Dialog.Title>Update Avatar</Dialog.Title>
          <Box component="form" onSubmit={handleSubmit}>
            <Dialog.Content>
              <CropImage
                image={image}
                onCrop={setCroppedImage}
                aspectRatio={1}
                dimensions={{ width: 300, height: 300 }}
              />
              <Box mt={4} />
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                loading={submitState.isFetching}
              >
                <span>Crop And Save</span>
              </LoadingButton>
            </Dialog.Content>
          </Box>
        </Dialog.Root>
      </Box>
    </Box>
  );
}
