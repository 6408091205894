import { Box, Button, Typography } from "@mui/material";
import { IconBrandTwitter } from "@tabler/icons-react";

import { Routes } from "~/constants/routes";
import { useOrigin } from "~/hooks/use-origin";

type ShareTwitterSectionProps = {
  enabled: boolean;
  handle: string;
};

export function ShareTwitterSection({ enabled, handle }: ShareTwitterSectionProps) {
  const origin = useOrigin();
  const wishlistUrl = `${origin}${Routes.public.wisher({ username: handle })}`;

  const params = new URLSearchParams({
    text: `You can buy a gift for me off my wishlist on @WishTender.\n\nWishlist: ${wishlistUrl}`,
  });

  const shareUrl = `https://twitter.com/intent/tweet?${params.toString()}`;

  return (
    <Box>
      <Typography variant="b5" color="neutral.5" component="p">
        <span>You have "Surprise Gifts" </span>
        {enabled ? <span>enabled</span> : <span>disabled</span>}
      </Typography>
      {enabled && (
        <Button
          variant="text"
          aria-label="Share on Twitter"
          size="medium"
          endIcon={<IconBrandTwitter size={20} />}
          onClick={(event) => {
            event.stopPropagation();
            window.open(shareUrl, "popup", "width=600,height=600");
          }}
          sx={{ px: 1, mt: 2 }}
        >
          Share Your Profile
        </Button>
      )}
    </Box>
  );
}
