import { Box, Slider, Typography } from "@mui/material";
import { useState } from "react";
import type { Area } from "react-easy-crop";
import Cropper from "react-easy-crop";

import { getCroppedImage } from "~/utils/image";

type CropImageProps = {
  image?: string;
  dimensions: {
    width: number;
    height: number;
  };
  onCrop?: (
    croppedImage?: Blob,
    croppedInfo?: { croppedArea: Area; croppedAreaPixels: Area },
  ) => void;
  aspectRatio?: number;
  cropShape?: "rect" | "round";
  showGrid?: boolean;
  slider?: boolean;
};

export function CropImage(props: CropImageProps) {
  const {
    image,
    dimensions,
    onCrop,
    aspectRatio = 1,
    cropShape = "round",
    showGrid,
    slider,
  } = props;

  const [crop, onCropChange] = useState({ x: 0, y: 0 });
  const [zoom, onZoomChange] = useState(1);

  async function handleCropComplete(croppedArea: Area, croppedAreaPixels: Area) {
    if (!image) return;
    let croppedImage: Blob | undefined;
    try {
      croppedImage = await getCroppedImage(image, croppedAreaPixels, dimensions);
    } catch {}
    onCrop?.(croppedImage, { croppedArea, croppedAreaPixels });
  }

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          position: "relative",
          height: 300,
          background: "darkgray",
          border: "1px solid lightgray",
          margin: "auto",
        }}
      >
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          onCropChange={onCropChange}
          onCropComplete={handleCropComplete}
          onZoomChange={onZoomChange}
          cropShape={cropShape}
          aspect={aspectRatio}
          showGrid={showGrid}
        />
      </Box>
      {slider && (
        <Box mt={4}>
          <Typography component="p" variant="b4" textAlign="center">
            Zoom
          </Typography>
          <Box>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(_, zoom) => onZoomChange(zoom as number)}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
