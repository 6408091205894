import type { IconButtonProps } from "@mui/material";
import { Box, IconButton } from "@mui/material";
import { IconArrowDown, IconArrowUp, IconMenu2 } from "@tabler/icons-react";

import type { WishlistItem } from "~/types";

import { isFavoriteItem } from "../utils/is-favorite-item";
import { ItemCrowdfundingIndicator } from "./item-crowdfunding-indicator";
import { ItemFavoriteIndicator } from "./item-favorite-indicator";

type ItemCardTopActionsProps = {
  item: WishlistItem;
  draggable?: boolean;
  dragHandleProps?: any;
  showMoveToTop?: boolean;
  showMoveToBottom?: boolean;
  onClickMoveToTop?: () => void;
  onClickMoveToBottom?: () => void;
};

export function ItemCardTopActions({
  item,
  draggable,
  dragHandleProps,
  showMoveToTop,
  showMoveToBottom,
  onClickMoveToBottom,
  onClickMoveToTop,
}: ItemCardTopActionsProps) {
  const isFavorite = isFavoriteItem(item);
  return (
    <Box sx={{ position: "absolute", top: 8, right: 0, left: 0, zIndex: 1 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", px: 1 }}>
        {draggable && (
          <ButtonAction {...dragHandleProps} title="Drag to reorder" sx={{ cursor: "move" }}>
            <IconMenu2 size={16} />
          </ButtonAction>
        )}
        {showMoveToBottom && (
          <ButtonAction
            title="Move to bottom"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onClickMoveToBottom?.();
            }}
          >
            <IconArrowDown size={16} />
          </ButtonAction>
        )}
        {showMoveToTop && (
          <ButtonAction
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onClickMoveToTop?.();
            }}
            title="Move to top"
          >
            <IconArrowUp size={16} />
          </ButtonAction>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "4px",
          flexWrap: "wrap",
          paddingRight: "8px",
        }}
      >
        {isFavorite ? <ItemFavoriteIndicator /> : <span />}
        {item.type === "crowdfunding" && <ItemCrowdfundingIndicator />}
      </Box>
    </Box>
  );
}

function ButtonAction({ title, sx, ...props }: IconButtonProps) {
  return (
    <IconButton
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#fff",
        color: "neutral.5",
        borderRadius: "50%",
        width: "28px",
        height: "28px",
        mb: 1,
        ":hover": {
          backgroundColor: "neutral.2",
        },
        ...sx,
      }}
      aria-label={title}
      title={title}
      {...props}
    />
  );
}
