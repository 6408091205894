import type { SxProps } from "@mui/material";
import { Box, Link, Typography } from "@mui/material";
import { IconAlertTriangleFilled } from "@tabler/icons-react";

import { useAuth } from "~/hooks/use-auth";
import type { Wisher } from "~/types";

type WishlistFrozenNoticeOwnerProps = {
  wisher: Wisher;
  sx?: SxProps;
};

export function WishlistFrozenNoticeOwner({ wisher, sx }: WishlistFrozenNoticeOwnerProps) {
  const { user } = useAuth();

  if (!wisher.frozen) return <></>;
  return (
    <Box sx={sx}>
      <Box
        sx={{
          borderRadius: "8px",
          border: "2px solid",
          borderColor: "yellow.4",
          p: 2,
          backgroundColor: "white",
          display: "flex",
          alignItems: { xs: "flex-start", sm: "center" },
          textAlign: { xs: "left", sm: "center" },
        }}
      >
        <Box sx={{ maxWidth: 600, mx: "auto" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "flex-start", sm: "center" },
              alignItems: { xs: "flex-start", sm: "center" },
              flexDirection: {
                xs: "row",
                lg: "column",
              },
              gap: 1,
            }}
          >
            <Box sx={{ color: "red.4", height: "24px" }}>
              <IconAlertTriangleFilled />
            </Box>
            <Typography variant="h6">Your account is frozen</Typography>
          </Box>
          <Typography variant="b5">
            Reach out to us in the customer support chat or email{" "}
            <Link href="mailto:support@wishtender.com">support@wishtender.com</Link> so we can
            unfreeze your account.
          </Typography>
          {user?.admin && (
            <Typography>
              <strong>Frozen reason: </strong> {wisher.frozenReason ?? "General"}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}
