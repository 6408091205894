import { ListItemText, Menu, MenuItem } from "@mui/material";
import { type MouseEvent, type ReactNode, useId, useState } from "react";

import type { OrderItemType } from "../types";

type OrderItemsMenuProps = {
  selected?: OrderItemType;
  onSelect: (orderType?: OrderItemType) => void;
  renderControl: (props: {
    open: boolean;
    controlId: string;
    menuId: string;
    toggle: (event: MouseEvent<HTMLButtonElement>) => void;
  }) => ReactNode;
  closeOnSelect?: boolean;
};

const ORDER_LIST: { type: OrderItemType; label: string }[] = [
  {
    type: "priceDesc",
    label: "Price: High to Low",
  },
  {
    type: "priceAsc",
    label: "Price: Low to High",
  },
  {
    type: "createdAtDesc",
    label: "Most recent",
  },
  {
    type: "createdAtAsc",
    label: "Oldest",
  },
];

export function SortItemsMenu({
  selected,
  renderControl,
  onSelect,
  closeOnSelect,
}: OrderItemsMenuProps) {
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement>();
  const menuId = useId();
  const controlId = useId();

  function handleOpen(event: MouseEvent<HTMLButtonElement>) {
    setAnchorElement(event.currentTarget);
  }

  function handleClose() {
    setAnchorElement(undefined);
  }

  function selectOrderType(orderType?: OrderItemType) {
    onSelect?.(orderType);
    if (closeOnSelect) handleClose();
  }

  const open = Boolean(anchorElement);

  return (
    <>
      {renderControl({ open, toggle: handleOpen, controlId, menuId })}
      <Menu
        id={menuId}
        open={open}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        MenuListProps={{
          "aria-labelledby": controlId,
          sx: { minWidth: "260px" },
        }}
        sx={{ mt: 1 }}
      >
        <MenuItem selected={!selected} onClick={() => selectOrderType()}>
          <ListItemText>Default</ListItemText>
        </MenuItem>
        {ORDER_LIST.map((order) => (
          <MenuItem
            key={order.type}
            selected={order.type === selected}
            onClick={() => selectOrderType(order.type)}
          >
            <ListItemText>{order.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
