import { Avatar as BaseAvatar, Box, IconButton } from "@mui/material";

import { CropImageDialog } from "~/components/crop-image-dialog";
import { UserAvatarIcon } from "~/components/icons";
import { CameraSparkleIcon } from "~/components/icons/camera-sparkle-icon";

import { AVATAR_DIMENSIONS } from "../../constants";

type AvatarInputProps = {
  defaultValue?: string;
  value?: Blob;
  onChange: (value: Blob) => void;
};

export function AvatarInput({ defaultValue, value, onChange }: AvatarInputProps) {
  return (
    <CropImageDialog
      dimensions={AVATAR_DIMENSIONS}
      title="Crop avatar image"
      onCropped={(file) => {
        onChange?.(file);
      }}
      renderControl={({ onSelect }) => {
        const avatarUrl = value ? URL.createObjectURL(value) : defaultValue;
        return (
          <Box
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              aspectRatio: "1/1",
              borderRadius: "50%",
              backgroundColor: "neutral.4",
              overflow: "hidden",
              border: "5px solid white",
            }}
          >
            <BaseAvatar
              src={avatarUrl}
              alt="Avatar"
              sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                backgroundColor: "neutral.4",
              }}
            >
              <UserAvatarIcon sx={{ width: "60%", height: "60%" }} />
            </BaseAvatar>
            <IconButton
              sx={{
                width: { xs: "100%", lg: "70%" },
                height: { xs: "100%", lg: "70%" },
                color: "white",
                backgroundColor: { xs: "#0b0f1066", lg: "#0B0F10b3" },
                "&:hover": {
                  backgroundColor: { xs: "#0b0f1080", lg: "#0B0F10cc" },
                },
              }}
              onClick={onSelect}
            >
              <CameraSparkleIcon width={32} height={32} />
            </IconButton>
          </Box>
        );
      }}
    />
  );
}
