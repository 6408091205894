import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Box, FormControl, FormLabel, MenuItem, Select, Typography } from "@mui/material";
import type { SubmitOptions } from "@remix-run/react";
import type { BaseSyntheticEvent } from "react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import type { z } from "zod";

import { Dialog } from "~/components/dialog";
import { TextField } from "~/components/form/text-field";
import { MoneyWithOriginal } from "~/components/money";
import { Routes } from "~/constants/routes";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";
import type { Currency, Money, WishlistItem } from "~/types";
import { calculatePercent } from "~/utils/calculate-percent";

import { ADD_AND_GO_TO_CART_BUTTON_ID } from "../constants";
import { AddToCartSchema } from "../schemas";
import { getIntervalSelectLabel, isSubscriptionItem } from "../utils/subscription";
import { ItemCrowdfundingProgress } from "./item-crowdfunding-progress";

type AddToCartDialogProps = {
  gifterCurrency: Currency;
  item: WishlistItem;
  onClosed?: () => void;
};

export function AddToCartDialog({ gifterCurrency, item, onClosed }: AddToCartDialogProps) {
  const [open, setOpen] = useState(false);
  const isCrowdfundingItem = item.type === "crowdfunding";
  const intervalOptions = (item.subscriptionIntervals || []).sort();

  const remaining: Money = {
    amount: item.price.amount - (item.totalContributionAmount?.amount || 0),
    currency: item.price.currency,
    from: item.price.from
      ? {
          amount: item.price.from.amount - (item.totalContributionAmount?.from?.amount || 0),
          currency: item.price.from.currency,
        }
      : undefined,
  };

  const form = useForm<z.infer<typeof AddToCartSchema>>({
    resolver: zodResolver(AddToCartSchema),
    defaultValues: {
      type: item.type,
      itemId: item.id,
      currency: gifterCurrency,
      interval: intervalOptions[0],
      contributionAmount: undefined,
    },
  });

  const [addAndContinue, addAndContinueState] = useWtFetcher({
    onSuccess: handleClose,
    onError: (response) => alert(response.error),
  });
  const [addAndCheckout, addAndCheckoutState] = useWtFetcher({
    onSuccess: handleClose,
    onError: (response) => alert(response.error),
  });

  function contributionPercent() {
    if (!item.totalContributionAmount?.amount) return 0;
    return calculatePercent(item.totalContributionAmount.amount, item.price.amount);
  }

  async function handleSubmit(
    data: z.infer<typeof AddToCartSchema>,
    event?: BaseSyntheticEvent<any>,
  ) {
    const goToCart = event?.nativeEvent?.submitter?.id === ADD_AND_GO_TO_CART_BUTTON_ID;
    const payload = {
      body: JSON.stringify({
        ...data,
        goToCart,
      }),
    };
    const options: SubmitOptions = {
      method: "POST",
      action: Routes.actions.cart.addItem(),
    };
    return goToCart
      ? addAndCheckout.submit(payload, options)
      : addAndContinue.submit(payload, options);
  }

  function handleClose() {
    setOpen(false);
    // Waiting for the animation to finish before navigating
    setTimeout(() => {
      onClosed?.();
    }, 200);
  }

  useEffect(() => {
    if (item) {
      setOpen(true);
    }
  }, [item]);

  return (
    <Dialog.Root
      maxWidth="xs"
      variant="normal"
      open={open}
      onClose={handleClose}
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          backgroundImage: "url(/images/background_bow_confetti-sm_optimized.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        },
        "& .MuiDrawer-paper": {
          backgroundImage: "url(/images/background_bow_confetti_xs_optimized.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        },
      }}
    >
      <Dialog.Title>Add To Cart</Dialog.Title>
      <form method="POST" onSubmit={form.handleSubmit(handleSubmit)}>
        <Dialog.Content sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <img
            style={{
              width: "170px",
              boxShadow: "20px 20px 30px grey",
              borderRadius: "4px",
              margin: "20px",
            }}
            src={item.thumbnail}
            alt={`${item.title} thumbnail`}
          ></img>
          <Box mt={2} />
          <Typography variant="b5" color="neutral.6">
            {item.title}
          </Typography>
          <Box mt={1} />
          <Typography variant="h5" component="p">
            <MoneyWithOriginal money={item.price} />
          </Typography>
          <Box mt={2} />

          {isSubscriptionItem(item) && (
            <Controller
              name="interval"
              control={form.control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <FormLabel id="subscription-interval-label">Subscription interval</FormLabel>
                  <Select labelId="subscription-interval" type="text" variant="outlined" {...field}>
                    {intervalOptions.map((interval) => {
                      return (
                        <MenuItem key={interval} value={interval}>
                          {getIntervalSelectLabel(interval)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            />
          )}

          {isCrowdfundingItem && (
            <>
              <Box width="100%">
                <ItemCrowdfundingProgress value={contributionPercent()} />
                <Box mt={0.5} />
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="caption">
                    <Typography component="span" color="textSecondary" variant="body2">
                      {contributionPercent()}%{" "}
                    </Typography>
                    granted
                  </Typography>
                  {remaining.amount > 0 && (
                    <Typography
                      variant="caption"
                      onClick={() => form.setValue("contributionAmount", remaining.amount)}
                      sx={{ cursor: "pointer", color: "primary.text", fontWeight: 500 }}
                    >
                      <Typography component="span" variant="body2">
                        <MoneyWithOriginal money={remaining} />
                      </Typography>
                      {" remaining"}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box mt={2} />
              <Box sx={{ width: "100%", "& > *": { width: "100%" } }}>
                <Controller
                  name="contributionAmount"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <TextField.Root fullWidth>
                      <TextField.Label htmlFor="contribution-amount">Amount</TextField.Label>
                      <TextField.InputMoney
                        id="contribution-amount"
                        currency={gifterCurrency}
                        {...field}
                      />
                      {fieldState.error && (
                        <TextField.Helper error>{fieldState.error.message}</TextField.Helper>
                      )}
                    </TextField.Root>
                  )}
                />
              </Box>
            </>
          )}

          <Box mt={4} />
          <LoadingButton
            fullWidth
            variant="contained"
            type="submit"
            loading={addAndContinueState.isFetching}
            disabled={addAndContinueState.isFetching || addAndCheckoutState.isFetching}
            sx={{ px: 2 }}
          >
            <span>Add To Cart And Keep Shopping</span>
          </LoadingButton>
          <Box mt={2} />
          <LoadingButton
            fullWidth
            variant="outlined"
            type="submit"
            loading={addAndCheckoutState.isFetching}
            disabled={addAndCheckoutState.isFetching || addAndContinueState.isFetching}
            id={ADD_AND_GO_TO_CART_BUTTON_ID}
            sx={{ px: 2 }}
          >
            <span>Add To Cart And Checkout</span>
          </LoadingButton>
        </Dialog.Content>
      </form>
    </Dialog.Root>
  );
}
