import { Button, Card, Typography } from "@mui/material";
import { Link } from "@remix-run/react";

import { Routes } from "~/constants/routes";
import type { Wisher } from "~/types";

import { WishlistActivationDialog } from "../activation/activation-dialog";

type WishlistUnactivatedNoticeProps = {
  wisher: Wisher;
};

export function WishlistUnactivatedNotice({ wisher }: WishlistUnactivatedNoticeProps) {
  const notice =
    wisher.activatedStatus === "started"
      ? "Finish setting up your account to receive funds. You have more steps to complete your payment setup."
      : "Set up your payments to receive funds and activate your wishlist";
  const cta = wisher.activatedStatus === "started" ? "Finish set up" : "Activate your wishlist";

  return (
    <Card
      sx={{
        width: "100%",
        backgroundColor: { lg: "neutral.2" },
        boxShadow: { lg: "none" },
        borderRadius: 2,
        padding: 2,
        display: "flex",
        gap: 2,
        flexDirection: { xs: "column", sm: "row", lg: "column" },
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        variant="b5"
        sx={{ flex: 1, textAlign: { xs: "center", sm: "left", lg: "center" } }}
      >
        {notice}
      </Typography>
      <WishlistActivationDialog
        isNewWisher
        wisherId={wisher.id}
        renderControl={({ toggle }) => (
          <Button
            component={Link}
            to={Routes.wisher.setupPayment()}
            variant="contained"
            color="info"
            sx={{ borderRadius: 2, px: 2, width: { xs: "auto", lg: "100%" } }}
          >
            {cta}
          </Button>
        )}
      />
    </Card>
  );
}
