import { Box, Typography } from "@mui/material";
import { IconClipboardList } from "@tabler/icons-react";

import type { Wisher, Wishlist } from "~/types";

import { AddNewItemDialog } from "./add-new-item";
import { AddWishButton } from "./add-wish-button";

type WishlistEmptyProps = {
  wisher: Wisher;
  wishlist: Wishlist;
};

export function WishlistEmpty({ wisher, wishlist }: WishlistEmptyProps) {
  return (
    <Box
      sx={{
        padding: 3,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: "teal.1",
          color: "blue.6",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: { xs: "48px", md: "64px", lg: "72px" },
          height: { xs: "48px", md: "64px", lg: "72px" },
          padding: { xs: "8px", md: "10px", lg: "12px" },
        }}
      >
        <IconClipboardList width="100%" height="100%" />
      </Box>
      <Typography variant="h8" component="p">
        Add your first wish
      </Typography>
      <Typography variant="b5" component="p" color="neutral.5" textAlign="center">
        You can use the URL of an existing product or customize your own wish
      </Typography>
      <AddNewItemDialog
        wishlist={wishlist}
        renderControl={({ toggle }) => <AddWishButton wisher={wisher} onClick={toggle} />}
      />
    </Box>
  );
}
