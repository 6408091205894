import { TabContext, TabPanel } from "@mui/lab";
import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import { Link } from "@remix-run/react";
import { type ReactNode, useState } from "react";
import { useToggle } from "usehooks-ts";

import { Dialog } from "~/components/dialog";
import { Routes } from "~/constants/routes";
import type { Wishlist } from "~/types";

import { AddNewItemForm } from "./add-new-item-form";
import { AddNewItemWithURLForm } from "./add-new-item-with-url-form";

type AddNewItemDialogProps = {
  wishlist: Wishlist;
  renderControl?: ({ toggle }: { toggle: () => void }) => ReactNode;
};

export function AddNewItemDialog({ wishlist, renderControl }: AddNewItemDialogProps) {
  const [open, toggle] = useToggle(false);
  const [tab, setTab] = useState("manual");

  return (
    <>
      {renderControl?.({ toggle })}
      <Dialog.Root maxWidth="xs" variant="normal" open={open} onClose={toggle} fullWidth>
        <Dialog.Title>Add A Wish</Dialog.Title>
        <Dialog.Content>
          <TabContext value={tab}>
            <Tabs
              centered
              onChange={(_, newValue) => {
                setTab(newValue);
              }}
              TabIndicatorProps={{
                sx: {
                  background: "linear-gradient(to left, rgb(0 126 255), rgb(2, 214, 242))",
                  height: "8px",
                  borderRadius: "4px 4px 0px 0px",
                },
              }}
              sx={{ minHeight: "48px" }}
              value={tab}
              aria-label="add new item tabs"
            >
              <Tab value="manual" label="Custom" />
              <Tab value="url" label="Prefill with URL" />
            </Tabs>
            <TabPanel value="manual" sx={{ p: 0 }}>
              <Box mt={2} />
              <AddNewItemForm wishlist={wishlist} onSuccess={toggle} />
            </TabPanel>
            <TabPanel value="url" sx={{ p: 0 }}>
              <Box sx={{ mx: { xs: "-24px", sm: "-32px" } }}>
                <ExtensionMessage />
              </Box>
              <Box mt={2} />
              <AddNewItemWithURLForm wishlist={wishlist} onSuccess={toggle} />
            </TabPanel>
          </TabContext>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
}

function ExtensionMessage() {
  return (
    <Box sx={{ backgroundColor: "#ffe678", p: 2 }}>
      <Typography component="p">Want to add wishes faster?</Typography>
      <Box mt={1} />
      <Typography component="p">
        Get the{" "}
        <Button
          component={Link}
          to={Routes.landing.extension()}
          variant="contained"
          color="primary"
          sx={{
            display: "inline",
            px: 1,
            mr: 1,
          }}
        >
          Add To WishTender{" "}
        </Button>{" "}
        Quick Button
      </Typography>
    </Box>
  );
}
