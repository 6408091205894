import { useEffect, useState } from "react";

import { Routes } from "~/constants/routes";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";
import type { RequestErrorResult, RequestSuccessResult } from "~/utils/request-helpers.server";

type PollingState = "idle" | "waiting" | "completed" | "failed";

type UsePollingItemWorkerProps = {
  interval?: number;
  onSuccess?: (response: RequestSuccessResult<any>) => void;
  onError?: (response: RequestErrorResult) => void;
};

export function usePollingItemWorker({
  interval = 2000,
  onSuccess,
  onError,
}: UsePollingItemWorkerProps) {
  const [state, setState] = useState<PollingState>("idle");
  const [intervalTimer, setIntervalTimer] = useState<ReturnType<typeof setInterval>>();
  const [submitPolling] = useWtFetcher<{ state: string }>({
    onSuccess: (response) => {
      if (response.data?.state === "completed") {
        setState("completed");
        clearInterval(intervalTimer);
        onSuccess?.(response);
      }
    },
    onError: (response) => {
      setState("failed");
      clearInterval(intervalTimer);
      onError?.(response);
    },
  });

  function start(job: { id: string }) {
    setState("waiting");
    const id = setInterval(() => {
      submitPolling.submit(
        { body: JSON.stringify({ jobId: job.id }) },
        {
          method: "POST",
          action: Routes.actions.wisher.wishlist.item.infoWorker(),
        },
      );
    }, interval);
    setIntervalTimer(id);
  }

  useEffect(() => {
    return () => {
      clearInterval(intervalTimer);
    };
  }, [intervalTimer]);

  return { start, state };
}
