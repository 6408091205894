import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import { IconPlus } from "@tabler/icons-react";
import type { ReactNode } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useToggle } from "usehooks-ts";
import type { z } from "zod";

import { Dialog } from "~/components/dialog";
import { Routes } from "~/constants/routes";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";
import type { SocialLink } from "~/types";

import { UpdateSocialLinksSchema } from "../../schemas";
import { SocialLinkOverlay, SortableSocialLink } from "./social-link-input";

type UpdateSocialLinksDialogProps = {
  wisherId: string;
  socialLinks: SocialLink[];
  renderControl: ({ toggle }: { toggle: () => void }) => ReactNode;
};

export function UpdateSocialLinksDialog({
  wisherId,
  renderControl,
  socialLinks,
}: UpdateSocialLinksDialogProps) {
  const [open, toggle] = useToggle(false);

  return (
    <>
      {renderControl({ toggle })}
      <Dialog.Root fullWidth maxWidth="sm" variant="normal" open={open} onClose={toggle}>
        <Dialog.Title>Update Your Social Links</Dialog.Title>
        <Dialog.Content>
          <UpdateSocialLinksForm wisherId={wisherId} socialLinks={socialLinks} onSuccess={toggle} />
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
}

type UpdateSocialLinksFormProps = {
  wisherId: string;
  socialLinks: SocialLink[];
  onSuccess?: () => void;
};

function UpdateSocialLinksForm({ wisherId, socialLinks, onSuccess }: UpdateSocialLinksFormProps) {
  const form = useForm<z.infer<typeof UpdateSocialLinksSchema>>({
    resolver: zodResolver(UpdateSocialLinksSchema),
    defaultValues: {
      wisherId,
      socialLinks,
    },
  });

  const { fields, append, remove, move } = useFieldArray({
    control: form.control,
    name: "socialLinks",
  });

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const activeIndex = fields.findIndex((link) => link.id === active.id);
      const overIndex = fields.findIndex((link) => link.id === over.id);
      move(activeIndex, overIndex);
    }
  }

  const [submit, submitState] = useWtFetcher({
    onSuccess,
    onError: (response) => alert(response.error),
  });

  function handleSubmit(data: z.infer<typeof UpdateSocialLinksSchema>) {
    submit.submit(
      {
        body: JSON.stringify(data),
      },
      {
        method: "POST",
        action: Routes.actions.wisher.profile.updateSocialLinks(),
      },
    );
  }

  return (
    <Box component="form" method="POST" onSubmit={form.handleSubmit(handleSubmit)}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <DndContext onDragEnd={handleDragEnd}>
          <SortableContext items={fields}>
            {fields.map((link, index) => (
              <SortableSocialLink
                key={link.id}
                id={link.id}
                form={form}
                index={index}
                onDelete={() => remove(index)}
              />
            ))}
          </SortableContext>
          <SocialLinkOverlay form={form} items={fields} />
        </DndContext>
      </Box>
      {fields.length === 0 && (
        <Typography variant="b5" sx={{ textAlign: "center", p: 2 }} component="p">
          You have no social links.
        </Typography>
      )}
      <Box mt={2} />
      <Box sx={{ textAlign: "center" }}>
        <Button
          variant="outlined"
          disabled={submitState.isFetching}
          startIcon={<IconPlus />}
          onClick={() => append({ platform: "", url: "" })}
        >
          Add link
        </Button>
      </Box>
      <Box mt={4} />
      <LoadingButton type="submit" fullWidth variant="contained" loading={submitState.isFetching}>
        <span>Save</span>
      </LoadingButton>
    </Box>
  );
}
