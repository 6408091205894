import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { IconBrandTwitterFilled, IconLink } from "@tabler/icons-react";

import { MoneyWithOriginal } from "~/components/money";
import { Routes } from "~/constants/routes";
import { useCopyWithTimeout } from "~/hooks/use-copy-with-timeout";
import { useOrigin } from "~/hooks/use-origin";
import type { WishlistItem } from "~/types";
import { calculatePercent } from "~/utils/calculate-percent";

import { isSubscriptionItem } from "../utils/subscription";
import { ItemCardTopActions } from "./item-card-top-actions";
import { ItemCrowdfundingProgress } from "./item-crowdfunding-progress";
import { ItemSubscriptionBadge } from "./item-subscription-badge";

type ItemCardProps = {
  wisherHandle: string;
  item: WishlistItem;
  onClick?: () => void;
  enableTwitterShare?: boolean;
  draggable?: boolean;
  dragHandleProps?: any;
  showMoveToTop?: boolean;
  showMoveToBottom?: boolean;
  onClickMoveToTop?: () => void;
  onClickMoveToBottom?: () => void;
};

export function ItemCard({
  item,
  wisherHandle,
  onClick,
  enableTwitterShare,
  draggable,
  dragHandleProps,
  showMoveToTop,
  showMoveToBottom,
  onClickMoveToTop,
  onClickMoveToBottom,
}: ItemCardProps) {
  const [isCopied, copy] = useCopyWithTimeout();
  const origin = useOrigin();

  const itemPath = Routes.public.wish({ username: wisherHandle, wishId: item.id });
  const itemUrl = `${origin}${itemPath}`;

  function contributionPercent() {
    if (!item.totalContributionAmount?.amount) return 0;
    return calculatePercent(item.totalContributionAmount.amount, item.price.amount);
  }

  function shareTwitter() {
    const params = new URLSearchParams({
      text: `I just added a new item to my wishlist.\n\nBuy it for me here: ${itemUrl}`,
    });
    const shareUrl = `https://twitter.com/intent/tweet?${params.toString()}`;
    window.open(shareUrl, "popup", "width=600,height=600");
  }

  return (
    <Card sx={{ height: "100%", position: "relative" }}>
      <ItemCardTopActions
        item={item}
        draggable={draggable}
        dragHandleProps={dragHandleProps}
        showMoveToTop={showMoveToTop}
        showMoveToBottom={showMoveToBottom}
        onClickMoveToBottom={onClickMoveToBottom}
        onClickMoveToTop={onClickMoveToTop}
      />
      <CardActionArea
        onClick={onClick}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "initial",
          justifyContent: "flex-start",
        }}
        draggable={false}
        title={item.title}
      >
        <CardMedia
          component="img"
          alt={item.title}
          width="100%"
          image={item.thumbnail}
          title={item.title}
          draggable={false}
          sx={{
            aspectRatio: "1 / 1",
            backgroundColor: "neutral.2",
          }}
        />
        <CardContent>
          <Typography
            variant="b5"
            color="neutral.6"
            component="h3"
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              display: "-webkit-box",
              height: "48px",
              wordBreak: "break-word",
            }}
          >
            {item.title}
          </Typography>
          <Typography variant="h5" component="p">
            <MoneyWithOriginal money={item.price} />
          </Typography>

          {isSubscriptionItem(item) && (
            <>
              <Box mt={1} />
              <ItemSubscriptionBadge subscriptionIntervals={item.subscriptionIntervals} />
            </>
          )}

          {item.type === "crowdfunding" && (
            <>
              <Box mt={1} />
              <ItemCrowdfundingProgress value={contributionPercent()} />
              <Box mt={0.5} />
              <Typography variant="caption">
                <Typography component="span" color="textSecondary" variant="body2">
                  {contributionPercent()}%{" "}
                </Typography>
                granted
              </Typography>
            </>
          )}
        </CardContent>
        <Box mt={6} />
      </CardActionArea>
      <CardActions
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          left: enableTwitterShare ? 0 : "auto",
          justifyContent: "space-between",
        }}
      >
        <Tooltip title={isCopied ? "Copied!" : "Copy link"}>
          <IconButton
            size="small"
            aria-label="Copy link"
            onClick={async (event) => {
              event.stopPropagation();
              await copy(itemUrl);
            }}
          >
            <IconLink size={20} />
          </IconButton>
        </Tooltip>
        {enableTwitterShare && (
          <Button
            variant="text"
            aria-label="Share on Twitter"
            size="small"
            endIcon={<IconBrandTwitterFilled size={20} />}
            onClick={(event) => {
              event.stopPropagation();
              shareTwitter();
            }}
            sx={{ px: 1, ml: 0.5 }}
          >
            Share
          </Button>
        )}
      </CardActions>
    </Card>
  );
}
