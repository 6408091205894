import { useMemo } from "react";

import { useSearchParamsNoReload } from "~/hooks/use-search-params-no-reload";

export function useEditProfileParams() {
  const [searchParams, setSearchParams] = useSearchParamsNoReload();

  const open = useMemo(() => {
    return searchParams.get("profile") === "edit";
  }, [searchParams]);

  function setOpen(value?: boolean) {
    const newParams = new URLSearchParams(searchParams);
    if (value) {
      newParams.set("profile", "edit");
    } else {
      newParams.delete("profile");
    }
    setSearchParams(newParams);
  }

  return [open, setOpen] as const;
}
