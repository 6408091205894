import { IconButton, ListItemText, Menu, MenuItem } from "@mui/material";
import { IconSquare, IconSquareCheckFilled } from "@tabler/icons-react";
import type { MouseEvent, ReactNode } from "react";
import { useId, useState } from "react";

import { CategoryMenuItemEditable } from "./category-menu-item-editable";

type CategoriesMenuEditableProps = {
  categories: string[];
  selectedCategories: string[];
  onSelectCategories: (categories: string[]) => void;
  renderControl: (props: {
    open: boolean;
    controlId: string;
    menuId: string;
    toggle: (event: MouseEvent<HTMLButtonElement>) => void;
  }) => ReactNode;
};

export function CategoriesMenuEditable(props: CategoriesMenuEditableProps) {
  const { categories, selectedCategories, onSelectCategories } = props;

  const selected = new Set(selectedCategories);

  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement>();
  const menuId = useId();
  const controlId = useId();

  function handleOpen(event: MouseEvent<HTMLButtonElement>) {
    setAnchorElement(event.currentTarget);
  }

  function handleClose() {
    setAnchorElement(undefined);
  }

  function toggleSelectItem(category: string) {
    if (selected.has(category)) {
      selected.delete(category);
    } else {
      selected.add(category);
    }
    onSelectCategories?.([...selected]);
  }

  function handleSelectAll() {
    onSelectCategories?.([]);
  }

  const open = Boolean(anchorElement);

  return (
    <>
      {props.renderControl({ open, toggle: handleOpen, controlId, menuId })}
      <Menu
        id={menuId}
        open={open}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        MenuListProps={{
          "aria-labelledby": controlId,
          sx: { minWidth: "260px", maxWidth: "320px" },
        }}
        sx={{ mt: 1 }}
      >
        <MenuItem selected={selected.size === 0} disableTouchRipple onClick={handleSelectAll}>
          <IconButton
            color="inherit"
            onClick={handleSelectAll}
            size="small"
            aria-label="Select all"
            title="Select all"
          >
            {selected.size === 0 ? <IconSquareCheckFilled /> : <IconSquare />}
          </IconButton>
          <ListItemText>All</ListItemText>
        </MenuItem>
        {categories.map((category) => (
          <CategoryMenuItemEditable
            key={category}
            category={category}
            selected={selected.has(category)}
            onSelect={() => toggleSelectItem(category)}
            onUpdated={() => {
              if (selected.has(category)) toggleSelectItem(category);
            }}
            onDeleted={() => {
              if (selected.has(category)) toggleSelectItem(category);
            }}
          />
        ))}
      </Menu>
    </>
  );
}
