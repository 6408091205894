import { Chip } from "@mui/material";

import { getIntervalLabel } from "../utils/subscription";

type ItemSubscriptionBadgeProps = {
  subscriptionIntervals?: number[];
};

export function ItemSubscriptionBadge({ subscriptionIntervals }: ItemSubscriptionBadgeProps) {
  if (!subscriptionIntervals || subscriptionIntervals.length === 0) return <></>;
  if (subscriptionIntervals.length === 1) {
    if (subscriptionIntervals[0] === 0) return <></>;
    return <Chip label={getIntervalLabel(subscriptionIntervals[0])} />;
  }
  return <Chip label="Subscribable" />;
}
