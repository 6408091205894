import { Box, Container, ThemeProvider } from "@mui/material";
import { Outlet } from "@remix-run/react";
import type { ReactNode } from "react";

import { GeneralLayout } from "~/components/general-layout";
import { themeV2 } from "~/utils/theme";

type WishlistPageLayoutProps = {
  header?: ReactNode;
  left: ReactNode;
  children: ReactNode;
};

export function WishlistPageLayout(props: WishlistPageLayoutProps) {
  return (
    <GeneralLayout>
      <ThemeProvider theme={themeV2}>
        <Box>
          {props.header}
          <Container maxWidth="xl">
            <Box
              sx={{
                display: {
                  xs: "block",
                  lg: "flex",
                },
                gap: "80px",
                pb: 2,
              }}
            >
              <Box
                sx={{
                  flex: "0 0 auto",
                  width: { lg: "320px", xl: "360px" },
                  transform: {
                    xs: "translateY(-40px)",
                    sm: "translateY(-56px)",
                    md: "translateY(-64px)",
                    lg: "translateY(-104px)",
                  },
                  marginBottom: { xs: "-40px", sm: "-56px", md: "-64px", lg: "-104px" },
                }}
              >
                <Box
                  sx={{ position: { xs: "unset", lg: "sticky" }, top: "calc(72px + 104px + 24px)" }}
                >
                  {props.left}
                </Box>
              </Box>
              <Box sx={{ width: "100%" }}>{props.children}</Box>
            </Box>
            <Box mt="100px" />
          </Container>
        </Box>
        <Outlet />
      </ThemeProvider>
    </GeneralLayout>
  );
}
