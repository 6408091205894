import { useRevalidator } from "@remix-run/react";
import { useState } from "react";

import { checkError } from "~/utils/fetch";

export function useAddItem({ onSuccess }: { onSuccess: () => any }) {
  const revalidator = useRevalidator();
  const [submitState, setSubmitState] = useState({ isFetching: false });

  async function submit(form: FormData) {
    try {
      setSubmitState({ isFetching: true });

      const response = await fetch(`${window.env.REACT_APP_BASE_URL}/api/wishlistItems/`, {
        method: "POST",
        credentials: "include",
        body: form,
      });
      await checkError(response);

      revalidator.revalidate();
      onSuccess();
    } catch (error: any) {
      console.error(error);
      alert(error?.message || "Unknown error");
    } finally {
      setSubmitState({ isFetching: false });
    }
  }

  return [submit, submitState] as const;
}
