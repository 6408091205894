import { Box, CircularProgress, useTheme } from "@mui/material";
import { IconCircleCheckFilled, IconCircleXFilled } from "@tabler/icons-react";
import type { ReactNode } from "react";

import type { HandleStatus } from "~/modules/auth/types";

export function HandleProgressIcon(props: { handleStatus: HandleStatus }) {
  const { handleStatus } = props;
  const theme = useTheme();

  const statusIcons: Record<HandleStatus, ReactNode> = {
    loading: <CircularProgress size={24} />,
    available: (
      <Box color={theme.palette.green[4]} sx={{ display: "flex" }}>
        <IconCircleCheckFilled />
      </Box>
    ),
    unavailable: (
      <Box color={theme.palette.red[4]} sx={{ display: "flex" }}>
        <IconCircleXFilled />
      </Box>
    ),
    error: (
      <Box color={theme.palette.red[4]} sx={{ display: "flex" }}>
        <IconCircleXFilled />
      </Box>
    ),
    initial: undefined,
  };

  return <>{statusIcons[handleStatus]}</> || <></>;
}
