import { useMemo } from "react";

import { useSearchParamsNoReload } from "~/hooks/use-search-params-no-reload";

export function useCategoriesParam() {
  const [searchParams, setSearchParams] = useSearchParamsNoReload();
  const selectedCategories = useMemo(
    () => searchParams.get("categories")?.split(",").filter(Boolean) || [],
    [searchParams],
  );

  function setSelectedCategories(categories: string[]) {
    const newParams = new URLSearchParams(searchParams);
    if (categories.length === 0) {
      newParams.delete("categories");
    } else {
      newParams.set("categories", categories.join(","));
    }
    setSearchParams(newParams);
  }

  return [selectedCategories, setSelectedCategories] as const;
}
