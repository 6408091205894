import { DragOverlay, useDndContext } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import DensityMediumRounded from "@mui/icons-material/DensityMediumRounded";
import LinkRounded from "@mui/icons-material/LinkRounded";
import {
  Box,
  Card,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Switch,
  Typography } from
"@mui/material";
import { IconTrash } from "@tabler/icons-react";
import { Controller, type FieldArrayWithId, type UseFormReturn } from "react-hook-form";
import type { z } from "zod";

import { TextField } from "~/components/form/text-field";
import type { SocialLink } from "~/types";

import { SOCIAL_PLATFORMS } from "../../constants";
import { useDragStyles } from "../../hooks/use-drag-styles";
import type { UpdateSocialLinksSchema } from "../../schemas";

type SocialLinkInputProps = {
  form: UseFormReturn<z.infer<typeof UpdateSocialLinksSchema>>;
  index: number;
  onDelete?: () => void;
  sortableProps?: any;
};

export function SocialLinkInput({ form, index, onDelete, sortableProps }: SocialLinkInputProps) {
  const { setNodeRef, attributes, listeners } = sortableProps || {};
  const { css, contentCss } = useDragStyles(sortableProps);

  const platformValue = form.watch(`socialLinks.${index}.platform`);
  const platform = SOCIAL_PLATFORMS[platformValue];
  const InputIcon = platform?.Icon || LinkRounded;

  return (
    <Box
      ref={setNodeRef}
      {...attributes}
      sx={{
        borderRadius: "7px",
        ...css
      }}>

      <Card
        sx={{
          display: "flex",
          border: "1px solid #eeeeee",
          position: "relative",
          ...contentCss
        }}>

        <Box
          {...listeners}
          sx={{
            cursor: "move",
            display: "flex",
            alignItems: "center",
            borderRight: "1px solid #d3d4d5",
            px: { xs: 0.5, sm: 1 }
          }}>

          <DensityMediumRounded sx={{ fontSize: 16 }} />
        </Box>
        <Box sx={{ p: { xs: 1, sm: 2 }, flex: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1
            }}>

            <div>
              <Controller
                name={`socialLinks.${index}.platform`}
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) =>
                <FormControl error={!!fieldState.error}>
                    <Select
                    type="text"
                    variant="outlined"
                    displayEmpty
                    inputRef={ref}
                    renderValue={() =>
                    platform?.name ||
                    <Typography variant="b5" color="neutral.5">
                            Select a platform
                          </Typography>}


                    {...field}>

                      {Object.entries(SOCIAL_PLATFORMS).map(([key, item]) => {
                      const Icon = item.Icon;
                      return (
                        <MenuItem key={key} value={key} sx={{ textTransform: "capitalize" }}>
                            <Icon sx={{ fontSize: 20, mr: 1 }} />
                            {item.name}
                          </MenuItem>);

                    })}
                    </Select>
                    {fieldState.error &&
                  <TextField.Helper error>{fieldState.error?.message}</TextField.Helper>}

                  </FormControl>} />


            </div>
            <Box sx={{ display: "flex" }}>
              <div>
                <Controller
                  name={`socialLinks.${index}.disabled`}
                  control={form.control}
                  render={({ field }) =>
                  <Switch
                    checked={!field.value}
                    onChange={(_, checked) => field.onChange(!checked)} />} />



              </div>
              <div>
                <IconButton aria-label="Delete link" onClick={onDelete}>
                  <IconTrash />
                </IconButton>
              </div>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}>

            <Controller
              name={`socialLinks.${index}.url`}
              control={form.control}
              render={({ field, fieldState }) =>
              <TextField.Root fullWidth>
                  <TextField.Input
                  placeholder="Enter platform URL"
                  {...field}
                  value={field.value || "https://"}
                  onChange={(event) => {
                    let url = event.target.value?.trim();
                    // We have a default value is "https://" in the input
                    // But some users just paste their URL copied from the browser
                    // So we need help them to remove the duplicated "https" for better UX
                    const duplicateProtocolRegex = /^(https?:\/\/){2,}/i;
                    if (duplicateProtocolRegex.test(url)) {
                      url = url.replace(duplicateProtocolRegex, "https://");
                    }
                    field.onChange(url);
                    const matchingPlatformEntry = Object.entries(SOCIAL_PLATFORMS).find(
                      ([_, platform]) => platform.baseUrl && url.startsWith(platform.baseUrl)
                    );
                    if (matchingPlatformEntry) {
                      form.setValue(`socialLinks.${index}.platform`, matchingPlatformEntry[0]);
                    }
                  }}
                  startAdornment={
                  <InputAdornment position="start">
                        <InputIcon sx={{ fontSize: 20 }} />
                      </InputAdornment>} />


                  {fieldState.error &&
                <TextField.Helper error>{fieldState.error.message}</TextField.Helper>}

                </TextField.Root>} />


          </Box>
        </Box>
      </Card>
    </Box>);

}

type SocialLinksListProps = Omit<SocialLinkInputProps, "sortableProps"> & {id: string;};

export function SortableSocialLink(props: SocialLinksListProps) {
  const sortableProps = useSortable({ id: props.id });
  return <SocialLinkInput {...props} sortableProps={sortableProps} />;
}

type SortableLinkItem = FieldArrayWithId<{socialLinks: SocialLink[];}, "socialLinks", "id">;

export function SocialLinkOverlay({
  items,
  form



}: {items: SortableLinkItem[];form: UseFormReturn<z.infer<typeof UpdateSocialLinksSchema>>;}) {
  const { active } = useDndContext();
  const activeIndex = items.findIndex((item) => item.id === active?.id);

  return (
    <DragOverlay>
      {activeIndex >= 0 && <SocialLinkInput form={form} index={activeIndex} />}
    </DragOverlay>);

}