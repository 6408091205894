import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { type SubmitOptions } from "@remix-run/react";
import { type BaseSyntheticEvent, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { Dialog } from "~/components/dialog";
import { TextField } from "~/components/form/text-field";
import { Routes } from "~/constants/routes";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";
import { createCurrencyConverter, useFormatCurrency } from "~/modules/currency";
import type { Currency, Money } from "~/types";

import { ADD_AND_GO_TO_CART_BUTTON_ID } from "../constants";

type SendATipDialogProps = {
  wisherId: string;
  minAmount?: Money;
  gifterCurrency: Currency;
  wisherCurrency: Currency;
  gifterToWisherRate?: number;
  defaultAmount?: number;
  defaultNote?: string;
  onClosed?: () => void;
};

export function SendATipDialog({ onClosed, ...props }: SendATipDialogProps) {
  const [open, setOpen] = useState(false);

  function handleClose() {
    setOpen(false);
    // Waiting for the animation to finish before navigating
    setTimeout(() => {
      onClosed?.();
    }, 200);
  }

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <Dialog.Root maxWidth="xs" variant="normal" open={open} onClose={handleClose}>
      <Dialog.Title>Send a Surprise Gift</Dialog.Title>
      <SendATipForm {...props} onSuccess={handleClose} />
    </Dialog.Root>
  );
}

type SendATipFormProps = Omit<SendATipDialogProps, "onClosed"> & {
  onSuccess?: () => void;
};

function SendATipForm({
  wisherId,
  minAmount,
  gifterCurrency,
  wisherCurrency,
  gifterToWisherRate = 1,
  defaultAmount,
  defaultNote,
  onSuccess,
}: SendATipFormProps) {
  const formatWisherCurrency = useFormatCurrency(wisherCurrency);

  const convertCurrency = createCurrencyConverter({
    from: gifterCurrency,
    to: wisherCurrency,
    exchangeRate: gifterToWisherRate,
  });

  const convertToGifterCurrency = createCurrencyConverter({
    from: wisherCurrency,
    to: gifterCurrency,
    exchangeRate: 1 / gifterToWisherRate,
  });

  const SendTipSchema = z.object({
    price: z
      .number({
        required_error: "Amount is required",
      })
      .refine((value) => value > 0, {
        message: "Amount must be greater than 0",
      })
      .refine(
        (value) => {
          if (!minAmount) return true;
          return value >= minAmount.amount;
        },
        {
          message: `This user set their minimum to ${formatWisherCurrency(
            minAmount!.from!.amount,
          )} ${wisherCurrency}.\nPlease enter a higher amount.`,
        },
      ),
    tipNote: z.string().optional(),
  });

  const form = useForm<z.infer<typeof SendTipSchema>>({
    resolver: zodResolver(SendTipSchema),
    defaultValues: {
      price: convertToGifterCurrency(defaultAmount ?? 0),
      tipNote: defaultNote,
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const [addAndContinue, addAndContinueState] = useWtFetcher({
    onSuccess,
    onError: (response) => alert(response.error),
  });
  const [addAndGoToCart, addAndGoToCartState] = useWtFetcher({
    onSuccess,
    onError: (response) => alert(response.error),
  });

  async function handleSubmit(
    data: z.infer<typeof SendTipSchema>,
    event?: BaseSyntheticEvent<any>,
  ) {
    const goToCart = event?.nativeEvent?.submitter?.id === ADD_AND_GO_TO_CART_BUTTON_ID;
    const payload = {
      body: JSON.stringify({
        ...data,
        currency: gifterCurrency,
        wisherId,
        goToCart,
      }),
    };
    const options: SubmitOptions = {
      method: "POST",
      action: Routes.actions.cart.addTipItem(),
    };
    return goToCart
      ? addAndGoToCart.submit(payload, options)
      : addAndContinue.submit(payload, options);
  }

  function displayAmountAsWisherCurrency(amount: number) {
    const amountConverted = formatWisherCurrency(convertCurrency(amount));
    return `${amountConverted} ${wisherCurrency}`;
  }

  return (
    <Box component="form" method="POST" onSubmit={form.handleSubmit(handleSubmit)}>
      <Dialog.Content>
        <Controller
          name="price"
          control={form.control}
          render={({ field, fieldState: { error } }) => {
            return (
              <>
                <TextField.Root error={!!error} fullWidth>
                  <TextField.Label>Amount</TextField.Label>
                  <TextField.InputMoney
                    {...field}
                    currency={gifterCurrency}
                    autoFocus
                    autoComplete="off"
                  />
                  {error && (
                    <TextField.Helper error sx={{ whiteSpace: "pre-line" }}>
                      {error.message}
                    </TextField.Helper>
                  )}
                </TextField.Root>
                {gifterCurrency !== wisherCurrency && (
                  <>
                    <Box mt={2} />
                    <Typography variant="b5" component="span" color="gray.5">
                      The amount is set to{" "}
                      <strong>{displayAmountAsWisherCurrency(field.value || 0)}</strong> in the
                      wisher's currency
                    </Typography>
                  </>
                )}
              </>
            );
          }}
        />

        <Box mt={2} />
        <Controller
          name="tipNote"
          control={form.control}
          render={({ field }) => {
            return (
              <TextField.Root fullWidth>
                <TextField.Label>Suggested use (optional)</TextField.Label>
                <TextField.Input {...field} autoComplete="off" />
              </TextField.Root>
            );
          }}
        />

        <Box mt={4} />
        <LoadingButton
          fullWidth
          variant="contained"
          type="submit"
          loading={addAndGoToCartState.isFetching}
          disabled={addAndGoToCartState.isFetching || addAndContinueState.isFetching}
          id={ADD_AND_GO_TO_CART_BUTTON_ID}
        >
          <span>Go To Cart</span>
        </LoadingButton>
        <Box mt={2} />
        <LoadingButton
          fullWidth
          variant="outlined"
          type="submit"
          loading={addAndContinueState.isFetching}
          disabled={addAndContinueState.isFetching || addAndGoToCartState.isFetching}
          sx={{ px: 2 }}
        >
          <span>Add To Cart And Keep Shopping</span>
        </LoadingButton>
      </Dialog.Content>
    </Box>
  );
}
