import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Box, InputAdornment, Typography } from "@mui/material";
import { useNavigate, useRevalidator } from "@remix-run/react";
import pick from "lodash/pick";
import { serialize } from "object-to-formdata";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import type { z } from "zod";

import { Dialog } from "~/components/dialog";
import { TextField } from "~/components/form/text-field";
import { HandleProgressIcon } from "~/components/handle-progress-icon";
import { Routes } from "~/constants/routes";
import { useOrigin } from "~/hooks/use-origin";
import { useValidateHandleInput } from "~/modules/auth";
import type { Wisher, Wishlist } from "~/types";
import { checkError } from "~/utils/fetch";

import { MAX_BIO_LENGTH } from "../../constants";
import { UpdateProfileSchema } from "../../schemas";
import { AddTagsInput } from "../add-tags-input";
import { AvatarInput } from "./avatar-input";
import { CoverInput } from "./cover-input";

type UpdateProfileDialogProps = {
  wisher: Wisher;
  wishlist: Wishlist;
  onClosed?: () => void;
};

export function UpdateProfileDialog({ wisher, wishlist, onClosed }: UpdateProfileDialogProps) {
  const [open, setOpen] = useState(false);

  function handleClose() {
    setOpen(false);
    // Waiting for the animation to finish before navigating
    setTimeout(() => {
      onClosed?.();
    }, 200);
  }

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <Dialog.Root maxWidth="sm" variant="normal" fullWidth open={open} onClose={handleClose}>
      <Dialog.Title>Edit Your Profile</Dialog.Title>
      <Box sx={{ px: { xs: "16px", sm: "32px" }, pb: { xs: "24px", sm: "48px" } }}>
        <UpdateProfileForm wisher={wisher} wishlist={wishlist} onSuccess={handleClose} />
      </Box>
    </Dialog.Root>
  );
}

type UpdateProfileFormProps = {
  wisher: Wisher;
  wishlist: Wishlist;
  onSuccess: () => void;
};

function useUpdateProfile({ onSuccess }: { onSuccess: () => any }) {
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const [submitState, setSubmitState] = useState({ isFetching: false });

  async function submit(
    { wishlistId, wisherId }: { wishlistId: string; wisherId: string },
    updatedData: Partial<z.infer<typeof UpdateProfileSchema>>,
  ) {
    setSubmitState({ isFetching: true });
    const promises = [];
    if (updatedData.wishlistName || updatedData.coverImage || updatedData.bio) {
      const wishlistForm = serialize({
        wishlistName: updatedData.wishlistName,
        wishlistMessage: updatedData.bio,
        image: updatedData.coverImage,
      });
      promises.push(
        new Promise((resolve, reject) => {
          fetch(`${window.env.REACT_APP_BASE_URL}/api/wishlists/${wishlistId}`, {
            method: "PATCH",
            credentials: "include",
            body: wishlistForm,
          })
            .then(checkError)
            .then(() => resolve(undefined))
            .catch((error) => reject(error));
        }),
      );
    }

    if (
      updatedData.handle ||
      updatedData.aliasName ||
      updatedData.tags ||
      updatedData.avatarImage
    ) {
      const aliasForm = serialize(
        {
          handle: updatedData.handle,
          aliasName: updatedData.aliasName,
          tags: updatedData.tags,
          image: updatedData.avatarImage,
        },
        {
          allowEmptyArrays: true,
        },
      );
      promises.push(
        new Promise((resolve, reject) => {
          fetch(`${window.env.REACT_APP_BASE_URL}/api/aliases/${wisherId}`, {
            method: "PATCH",
            credentials: "include",
            body: aliasForm,
          })
            .then(checkError)
            .then(() => resolve(undefined))
            .catch((error) => reject(error));
        }),
      );
    }

    try {
      await Promise.all(promises);

      if (updatedData.handle) {
        return navigate(Routes.public.wisher({ username: updatedData.handle }));
      } else {
        revalidator.revalidate();
      }
      onSuccess();
    } catch (error: any) {
      alert(error?.message || "Unknown error");
    } finally {
      setSubmitState({ isFetching: false });
    }
  }

  return [submit, submitState] as const;
}

function UpdateProfileForm({ wisher, wishlist, onSuccess }: UpdateProfileFormProps) {
  const origin = useOrigin();

  const form = useForm<z.infer<typeof UpdateProfileSchema>>({
    resolver: zodResolver(UpdateProfileSchema),
    defaultValues: {
      wisherId: wisher.id,
      wishlistId: wishlist.id,
      handle: wisher.handle,
      aliasName: wisher.name,
      wishlistName: wishlist.name,
      tags: wisher.tags,
      bio: wisher.bio,
    },
  });
  const [submit, submitState] = useUpdateProfile({
    onSuccess,
  });

  const { getHandleStatus, validateState } = useValidateHandleInput(form, wisher.handle, true);

  const handleStatus = getHandleStatus();

  const isFormSubmittable = useMemo(() => {
    if (!form.formState.isDirty) return false;
    if (!form.formState.isValid) return false;
    if (validateState.isFetching) return false;
    if (validateState.isError) return false;
    if (submitState.isFetching) return false;

    return handleStatus === "available" || handleStatus === "initial";
  }, [
    form.formState.isDirty,
    form.formState.isValid,
    handleStatus,
    submitState.isFetching,
    validateState.isError,
    validateState.isFetching,
  ]);

  async function handleSubmit(data: z.infer<typeof UpdateProfileSchema>) {
    const dirtyFields = form.formState.dirtyFields;
    const updatedData = pick(data, ["wisherId", "wishlistId", ...Object.keys(dirtyFields)]);
    await submit({ wisherId: wisher.id, wishlistId: wishlist.id }, updatedData);
  }

  function CharactersRemaining({ value }: { value?: string }) {
    return (
      <span>
        <span>{value?.length}</span>/<span>{MAX_BIO_LENGTH}</span>
      </span>
    );
  }

  function FullCharactersRemaining() {
    return <span>{MAX_BIO_LENGTH} characters remaining</span>;
  }

  return (
    <Box component="form" onSubmit={form.handleSubmit(handleSubmit)}>
      <Controller
        name="coverImage"
        control={form.control}
        render={({ field }) => (
          <Box sx={{ mx: { xs: "-16px", lg: 0 } }}>
            <CoverInput
              defaultValue={wisher.coverUrl}
              value={field.value}
              onChange={field.onChange}
            />
          </Box>
        )}
      />
      <Box mt={2} />
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: { xs: "flex-start", lg: "center" },
          flexDirection: { xs: "column", lg: "row" },
          transform: { xs: "translateY(-50px)", lg: "none" },
          mb: { xs: "-50px", lg: "0" },
        }}
      >
        <Controller
          name="avatarImage"
          control={form.control}
          render={({ field }) => (
            <Box
              sx={{
                flex: "0 0 auto",
                width: { xs: "88px", lg: "104px" },
                height: { xs: "88px", lg: "104px" },
              }}
            >
              <AvatarInput
                defaultValue={wisher.avatarUrl}
                value={field.value}
                onChange={field.onChange}
              />
            </Box>
          )}
        />
        <Controller
          name="aliasName"
          control={form.control}
          render={({ field, fieldState }) => (
            <TextField.Root fullWidth>
              <TextField.Label>Display Name</TextField.Label>
              <TextField.Input placeholder="Your display name" autoComplete="off" {...field} />
              {fieldState.error && (
                <TextField.Helper error>{fieldState.error.message}</TextField.Helper>
              )}
            </TextField.Root>
          )}
        />
        <Controller
          name="wishlistName"
          control={form.control}
          render={({ field, fieldState }) => (
            <TextField.Root fullWidth>
              <TextField.Label>Wishlist Name</TextField.Label>
              <TextField.Input placeholder="Your wishlist name" autoComplete="off" {...field} />
              {fieldState.error && (
                <TextField.Helper error>{fieldState.error.message}</TextField.Helper>
              )}
            </TextField.Root>
          )}
        />
      </Box>
      <Box mt={2} />
      <Controller
        name="bio"
        control={form.control}
        render={({ field, fieldState }) => (
          <TextField.Root fullWidth>
            <TextField.Label>
              <span>Bio</span>
              <Typography color="neutral.5" ml={1}>
                {field.value?.length ? (
                  <CharactersRemaining value={field.value} />
                ) : (
                  <FullCharactersRemaining />
                )}
              </Typography>
            </TextField.Label>
            <TextField.Input
              autoComplete="off"
              spellCheck="false"
              placeholder="About you"
              multiline
              rows={3}
              {...field}
              onChange={(event) => {
                // Prevent new lines, and limit to MAX_BIO_LENGTH
                const value = event.target.value
                  .replace(/(\r\n|\n)/gm, "")
                  .slice(0, MAX_BIO_LENGTH);
                field.onChange(value);
              }}
            />

            {fieldState.error && (
              <TextField.Helper error>{fieldState.error.message}</TextField.Helper>
            )}
          </TextField.Root>
        )}
      />
      <Box mt={2} />
      <Controller
        name="handle"
        control={form.control}
        render={({ field }) => (
          <TextField.Root fullWidth>
            <TextField.Label>Wishlist URL</TextField.Label>
            <TextField.Input
              autoComplete="off"
              spellCheck="false"
              placeholder="yourname"
              startAdornment={
                <InputAdornment position="start">
                  <span>wishtender.com/</span>
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <HandleProgressIcon handleStatus={handleStatus} />
                </InputAdornment>
              }
              {...field}
            />
            <TextField.Helper sx={{ fontSize: "14px", lineHeight: "22px", mx: 0, mt: 1 }}>
              {origin}
              {Routes.public.wisher({ username: field.value || "yourname" })}
            </TextField.Helper>
          </TextField.Root>
        )}
      />
      <Box mt={4} />
      <AddTagsInput control={form.control} name="tags" />
      <Box mt={4} />
      <LoadingButton
        fullWidth
        variant="contained"
        type="submit"
        loading={submitState.isFetching}
        disabled={!isFormSubmittable}
      >
        <span>Update Profile</span>
      </LoadingButton>
    </Box>
  );
}
