import { Box } from "@mui/material";

export function ItemFavoriteIndicator() {
  return (
    <Box
      sx={{
        color: "#fff",
        width: "70px",
        height: "20px",
        margin: "0 0 5px",
        display: "block",
        padding: "0 2px 0 5px",
        position: "relative",
        fontSize: "10px",
        textAlign: "center",
        fontWeight: "800",
        lineHeight: "20px",
        borderRadius: "0",
        letterSpacing: ".8px",
        textTransform: "uppercase",
        backgroundColor: "#03abd8",
        "&:after": {
          content: "''",
          top: 0,
          left: "100%",
          width: 0,
          height: 0,
          display: "block",
          position: "absolute",
          borderColor: "rgba(0,0,0,0)",
          borderStyle: "solid",
          borderWidth: "20px 10px 0 0",
          borderTopColor: "#03abd8",
        },
      }}
    >
      Favorite
    </Box>
  );
}
