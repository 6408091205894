import { Box } from "@mui/material";

type CoverProps = {
  src?: string;
  alt: string;
};

export function Cover({ src, alt }: CoverProps) {
  return (
    <Box
      sx={{
        height: { xs: 118, sm: 166, md: 208, lg: 360, xl: 400 },
        width: "100%",
        overflow: "hidden",
        backgroundColor: "gray.4",
        "& img": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
          filter: "brightness(0.8)",
        },
      }}
    >
      {src && <img src={src} alt={alt} />}
    </Box>
  );
}
