import { Box, Typography } from "@mui/material";
import { IconAlertTriangleFilled } from "@tabler/icons-react";

import type { Wisher } from "~/types";

type WishlistFrozenNoticePublicProps = {
  wisher: Wisher;
};

export function WishlistFrozenNoticePublic({ wisher }: WishlistFrozenNoticePublicProps) {
  if (!wisher.frozen) return <></>;
  return (
    <Box sx={{ display: "flex", justifyContent: "center", px: 2, py: 5, textAlign: "center" }}>
      <Box sx={{ maxWidth: 600, mx: "auto" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Box sx={{ color: "red.4", height: "24px" }}>
            <IconAlertTriangleFilled size={24} />
          </Box>
          <Typography variant="h7">This account is frozen</Typography>
        </Box>
        <Box mt={3} />
        <Typography variant="b5">
          If you want to buy a gift for this user,
          <br /> please have them reach out to us so we can unfreeze their account.
        </Typography>
      </Box>
    </Box>
  );
}
