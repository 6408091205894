import { Box, Button, useTheme } from "@mui/material";
import { useNavigate } from "@remix-run/react";
import { IconPlus } from "@tabler/icons-react";

import { Routes } from "~/constants/routes";
import { primaryGradientBackgroundStyle } from "~/constants/styles";
import type { Wisher } from "~/types";

import { useCommunityNotice } from "../hooks/use-notices";

type AddWishButtonProps = {
  wisher: Wisher;
  onClick?: () => void;
};

export function AddWishButton({ wisher, onClick }: AddWishButtonProps) {
  const theme = useTheme();
  const communityNotice = useCommunityNotice();
  const navigate = useNavigate();
  const communityNoticeUrl = Routes.wisher.communityNotice({ username: wisher.handle });

  return (
    <Button
      disableElevation
      variant="contained"
      endIcon={
        <Box
          sx={{
            width: "16px",
            height: "16px",
            borderRadius: "50%",
            borderWidth: 1.5,
            borderStyle: "solid",
            borderColor: "neutral.0",
            display: "grid",
            placeContent: "center",
          }}
        >
          <IconPlus
            size={8}
            style={{
              color: theme.palette.neutral[0],
              strokeWidth: 5,
            }}
          />
        </Box>
      }
      sx={{
        ...primaryGradientBackgroundStyle,
        height: { xs: "40px", lg: "44px" },
        px: { xs: 3, lg: 4 },
        fontSize: { xs: "14px", lg: "16px" },
        lineHeight: "24px",
        fontWeight: 600,
      }}
      onClick={
        communityNotice ? () => navigate(communityNoticeUrl, { preventScrollReset: true }) : onClick
      }
    >
      Add a Wish
    </Button>
  );
}
