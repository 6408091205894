import { Avatar as BaseAvatar, Box } from "@mui/material";

import { UserAvatarIcon } from "~/components/icons";

type AvatarProps = {
  src: string;
  alt: string;
};

export function Avatar({ src, alt }: AvatarProps) {
  return (
    <Box sx={{ border: "5px solid #fff", borderRadius: "50%", backgroundColor: "#fff" }}>
      <BaseAvatar
        src={src}
        alt={alt}
        sx={{
          width: { xs: "88px", sm: "104px", md: "120px", lg: "160px", xl: "200px" },
          height: { xs: "88px", sm: "104px", md: "120px", lg: "160px", xl: "200px" },
          backgroundColor: "neutral.4",
        }}
      >
        <UserAvatarIcon
          sx={{
            width: { xs: "44px", sm: "52px", md: "64px", lg: "86px", xl: "106px" },
            height: { xs: "44px", sm: "52px", md: "64px", lg: "86px", xl: "106px" },
          }}
        />
      </BaseAvatar>
    </Box>
  );
}
