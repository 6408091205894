import { useRouteLoaderData } from "@remix-run/react";

import { RouteIds } from "~/constants/routes";
import type { Notice } from "~/types";

export function useNotices() {
  return (useRouteLoaderData(RouteIds.public.wishlist()) as { notices: Notice[] }).notices;
}

export function useCommunityNotice() {
  const notices = useNotices();
  return notices[0];
}
